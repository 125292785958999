import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../scss/subscriptionPage.scss";
import { API_ENDPOINTS } from "../api/api";

function SubscriptionPage() {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [stripeUrls, setStripeUrls] = useState({});
  const [currentPlan, setCurrentPlan] = useState("starter");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch plans
        const plansResponse = await fetch(API_ENDPOINTS.GET_PLANS);
        const plansData = await plansResponse.json();

        // Fetch current plan
        const email = localStorage.getItem("demoEmail");
        const planResponse = await fetch(
          `${API_ENDPOINTS.GET_USER_CURRENT_PLAN}/${email}`,
        );
        const planData = await planResponse.json();

        // Fetch all Stripe URLs
        const [hobbyistRes, creatorRes, largerCreatorRes, portalRes] =
          await Promise.all([
            fetch(API_ENDPOINTS.GET_STRIPE_HOBBYIST_URL),
            fetch(API_ENDPOINTS.GET_STRIPE_CREATOR_URL),
            fetch(API_ENDPOINTS.GET_STRIPE_LARGER_CREATOR_URL),
            fetch(API_ENDPOINTS.GET_STRIPE_PORTAL_URL),
          ]);

        const [hobbyist, creator, largerCreator, portal] = await Promise.all([
          hobbyistRes.json(),
          creatorRes.json(),
          largerCreatorRes.json(),
          portalRes.json(),
        ]);

        setStripeUrls({
          hobbyist: hobbyist.success ? hobbyist.url : "",
          creator: creator.success ? creator.url : "",
          largerCreator: largerCreator.success ? largerCreator.url : "",
          portal: portal.success ? portal.url : "",
        });

        if (plansData.success) {
          const plansArray = Object.entries(plansData.plans).map(
            ([key, plan]) => ({
              id: key,
              name: plan.name,
              price: plan.monthly_pay === 0 ? "Free" : `$${plan.monthly_pay}`,
              quota: plan.quota.toLocaleString(),
              features: plan.features,
            }),
          );
          setPlans(plansArray);
        }

        if (planData.success) {
          setCurrentPlan(planData.plan);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleBackLanding = () => {
    navigate("/demo");
  };

  const handleSubscribe = (planId, planName) => {
    const email = localStorage.getItem("demoEmail");

    // If user is on any paid plan (not starter), redirect to portal
    if (currentPlan !== "starter") {
      window.location.href = `${stripeUrls.portal}?prefilled_email=${encodeURIComponent(email)}`;
      return;
    }

    // For free tier users, redirect to specific plan payment link
    const planUrlMap = {
      hobbyist: stripeUrls.hobbyist,
      creator: stripeUrls.creator,
      larger_creator: stripeUrls.largerCreator,
    };

    const stripeUrl = planUrlMap[planId];
    if (stripeUrl) {
      window.location.href = `${stripeUrl}?prefilled_email=${encodeURIComponent(email)}`;
    }
  };

  return (
    <div className="subscription-page">
      <img
        className="product-title"
        onClick={handleBackLanding}
        src="/audiowizard.png"
        alt="AudioWizard"
      />
      <h1>Choose Your Plan</h1>
      <div className="plans-container">
        {plans.map((plan) => (
          <div key={plan.id} className="plan-card">
            <h2>{plan.name}</h2>
            {!plan.isEnterprise && (
              <>
                <div className="price">{plan.price}</div>
                <div className="quota">{plan.quota} quota</div>
              </>
            )}
            <ul>
              {plan.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            {plan.id === currentPlan ? (
              <button className="current-plan-button" disabled>
                Current Plan
              </button>
            ) : (
              <button
                className="subscribe-button"
                onClick={() => handleSubscribe(plan.id, plan.name)}
              >
                {currentPlan === "starter" ? "Subscribe" : "Change Plan"}
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SubscriptionPage;
