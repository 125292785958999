import { API_ENDPOINTS } from "./api";

export const transformTextToScreenplay = async (text, email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.TRANSFORM, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text,
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during transformation:", error);
    throw error;
  }
};

/**
 * Subscribe the email to MailerLite and initialize the quota in the MongoDB.
 */
export const subscribeToMailerLite = async (
  email,
  password = "",
  isSignUp = false,
) => {
  try {
    const response = await fetch(API_ENDPOINTS.SUBSCRIBE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, isSignUp }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error subscribing to MailerLite:", error);
    throw new Error("Failed to subscribe");
  }
};

// export const assignVoicesToCharacters = async (dialogues, email, timestamp) => {
export const assignVoicesToCharacters = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.ASSIGN_VOICES, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during voice assignment:", error);
    throw error;
  }
};

export const generateVoices = async (email, timestamp) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 10 * 60 * 1000); // 10 mins timeout

  console.log(`(debug super) Generating voices for ${email} ${timestamp}`);
  try {
    const response = await fetch(API_ENDPOINTS.GENERATE_VOICES, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
      signal: controller.signal,
    });

    clearTimeout(timeoutId);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data.success;
  } catch (error) {
    clearTimeout(timeoutId);
    if (error.name === "AbortError") {
      console.error(
        "Request timed out but the generation might still be processing in the backend",
      );
      throw new Error(
        "Request timed out. Please check the results page in a few minutes.",
      );
    }
    console.error("Error generating voices:", error);
    throw error;
  }
};

export const getVoiceGeneration = async (
  email,
  timestamp,
  dialogueIndex,
  isShared = false,
) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_VOICE_GENERATION}/${email}/${timestamp}/${dialogueIndex}?is_shared=${isShared}&t=${Date.now()}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  } catch (error) {
    console.error("Error getting voice generation:", error);
    throw error;
  }
};

export const getWordAlignment = async (
  email,
  timestamp,
  dialogueIndex,
  isShared = false,
) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_WORD_ALIGNMENT}/${email}/${timestamp}/${dialogueIndex}?is_shared=${isShared}&t=${Date.now()}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error getting word alignment:", error);
    throw error;
  }
};

export const getMultitrack = async (
  email,
  timestamp,
  character,
  isShared = false,
) => {
  try {
    const encodedCharacter = encodeURIComponent(character);
    const url = `${API_ENDPOINTS.GET_MULTITRACK}/${email}/${timestamp}/${encodedCharacter}?is_shared=${isShared}&t=${Date.now()}`;

    console.log("Fetching multitrack from:", url);

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  } catch (error) {
    console.error("Error getting multitrack:", error);
    throw error;
  }
};

export const getCombinedTrack = async (email, timestamp, isShared = false) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_COMBINED_TRACK}/${email}/${timestamp}?is_shared=${isShared}&t=${Date.now()}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  } catch (error) {
    console.error("Error getting combined track:", error);
    throw error;
  }
};

export const getSeparateTracksZip = async (email, timestamp) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_SEPARATE_TRACKS_ZIP}/${email}/${timestamp}?t=${Date.now()}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  } catch (error) {
    console.error("Error getting separate tracks zip:", error);
    throw error;
  }
};

export const getRemainingQuota = async (email) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_REMAINING_QUOTA}?email=${encodeURIComponent(email)}`,
      requestOptions,
    );
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Get quota failed");
    }

    // The request was successful
    return {
      success: data.success,
      quota: data.quota,
    };
  } catch (error) {
    console.error("Get remaining quota error:", error.message);
    throw new Error(error.message || "Failed to get remaining quota");
  }
};

export const calculateQuotaUsage = async (dialogues) => {
  try {
    const response = await fetch(API_ENDPOINTS.CALCULATE_QUOTA_USAGE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dialogues: dialogues }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.quota_needed;
  } catch (error) {
    console.error("Error calculating quota usage:", error);
    throw error;
  }
};

export const subtractQuota = async (email, amount) => {
  try {
    const response = await fetch(API_ENDPOINTS.SUBTRACT_QUOTA, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, amount }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error("Error subtracting quota:", error);
    throw error;
  }
};

export const generateBGMDescription = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.GENERATE_BGM_DESCRIPTION, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.bgm_description;
  } catch (error) {
    console.error("Error generating BGM description:", error);
    throw error;
  }
};

export const generateBackgroundMusic = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.GENERATE_BGM, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error("Error generating background music:", error);
    throw error;
  }
};

export const getBGMTrack = async (email, timestamp, isShared = false) => {
  try {
    console.log(`(debug super )${isShared}`);
    const response = await fetch(
      `${API_ENDPOINTS.GET_BGM_TRACK}/${email}/${timestamp}?is_shared=${isShared}&t=${Date.now()}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  } catch (error) {
    console.error("Error getting BGM track:", error);
    throw error;
  }
};

export const getBGMAudio = async (email, timestamp) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_BGM_AUDIO}/${email}/${timestamp}`,
    );
    if (!response.ok) throw new Error("Failed to get BGM audio");
    return await response.blob();
  } catch (error) {
    console.error("Error getting BGM audio:", error);
    throw error;
  }
};

export const generateSoundEffectsDescription = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.GENERATE_SFX_DESCRIPTION, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.sound_effects_dir;
  } catch (error) {
    console.error("Error generating sound effects description:", error);
    throw error;
  }
};

export const getSoundEffectsDescription = async (email, timestamp) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_SFX_DESCRIPTION}/${email}/${timestamp}?t=${Date.now()}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error getting sound effects description:", error);
    throw error;
  }
};

export const saveModifiedSoundEffects = async (
  email,
  timestamp,
  soundEffectsData,
) => {
  try {
    console.log(
      `(saveModifiedSoundEffects) (debug 11) soundEffectsData: ${JSON.stringify(
        soundEffectsData,
      )}`,
    );
    const response = await fetch(API_ENDPOINTS.SAVE_MODIFIED_SFX, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
        sound_effects_data: soundEffectsData,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to save modified sound effects");
    }

    return await response.json();
  } catch (error) {
    console.error("Error saving modified sound effects:", error);
    throw error;
  }
};

export const generateSoundEffects = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.GENERATE_SFX, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error("Error generating sound effects:", error);
    throw error;
  }
};

export const getSoundEffectsTrack = async (
  email,
  timestamp,
  isShared = false,
) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_SFX_TRACK}/${email}/${timestamp}?is_shared=${isShared}&t=${Date.now()}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  } catch (error) {
    console.error("Error getting sound effects track:", error);
    throw error;
  }
};

export const applySingleVoiceLoudnessAndSpeedChanges = async (
  email,
  timestamp,
  dialogue_index,
  dialogue,
) => {
  const response = await fetch(
    API_ENDPOINTS.APPLY_SINGLE_VOICE_LOUDNESS_AND_SPEED_CHANGES,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        timestamp: timestamp,
        dialogue_index: dialogue_index,
        dialogue: dialogue,
      }),
    },
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const saveModifiedScreenplay = async (screenplay, email, timestamp) => {
  try {
    // Log the screenplay before saving to verify order indices
    console.log(
      "Saving screenplay with order indices:",
      screenplay.map((d) => ({
        character: d.character,
        order_index: d.order_index,
      })),
    );

    const response = await fetch(API_ENDPOINTS.SAVE_MODIFIED_SCREENPLAY, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
        screenplay: JSON.stringify(screenplay),
      }),
    });
    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error("Error saving modified screenplay:", error);
    return false;
  }
};

export const getUserProjects = async (email) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.GET_USER_PROJECTS}/${email}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching user projects:", error);
    throw error;
  }
};

export const shareProject = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.SHARE_PROJECT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, timestamp }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error sharing project:", error);
    throw error;
  }
};

export const getCommunityProjects = async (userEmail) => {
  try {
    const url = `${API_ENDPOINTS.GET_COMMUNITY_PROJECTS}${userEmail ? `?userEmail=${encodeURIComponent(userEmail)}` : ""}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching community projects:", error);
    throw error;
  }
};

export const getCommunityProject = async (email, timestamp) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_COMMUNITY_PROJECT}/${email}/${timestamp}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching community project:", error);
    throw error;
  }
};

export const addReaction = async (
  userEmail,
  projectEmail,
  projectTimestamp,
  reaction,
) => {
  try {
    const response = await fetch(API_ENDPOINTS.ADD_REACTION, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userEmail,
        projectEmail,
        projectTimestamp,
        reaction,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error adding reaction:", error);
    throw error;
  }
};

export const getSoundEffectAudio = async (
  email,
  timestamp,
  dialogue_order_index,
  start_word_idx,
  end_dialogue_order_index,
  end_word_idx,
  isShared = false,
) => {
  try {
    console.log(`(getSoundEffectAudio) (debug super) ${isShared}`);
    const response = await fetch(
      `${API_ENDPOINTS.GET_SFX_AUDIO}/${email}/${timestamp}/${dialogue_order_index}/${start_word_idx}/${end_dialogue_order_index}/${end_word_idx}?is_shared=${isShared}&t=${Date.now()}`,
      {
        method: "GET",
      },
    );

    if (!response.ok) {
      throw new Error("Failed to fetch sound effect audio");
    }

    return await response.blob();
  } catch (error) {
    console.error("Error fetching sound effect audio:", error);
    throw error;
  }
};

export const regenerateSingleSoundEffect = async (
  email,
  timestamp,
  dialogue_order_index,
  startWordIdx,
  end_dialogue_order_index,
  endWordIdx,
  description,
) => {
  try {
    const response = await fetch(API_ENDPOINTS.REGENERATE_SINGLE_SFX, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
        dialogue_order_index: dialogue_order_index,
        start_word_idx: startWordIdx,
        end_dialogue_order_index: end_dialogue_order_index,
        end_word_idx: endWordIdx,
        description,
      }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error regenerating sound effect:", error);
    throw error;
  }
};

export const applyModifiedSoundEffects = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.APPLY_SFX, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error applying sound effects:", error);
    throw error;
  }
};

export const deleteSoundEffect = async (email, timestamp, sfx) => {
  try {
    const response = await fetch(API_ENDPOINTS.DELETE_SFX, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
        dialogue_order_index: sfx.dialogue_order_index,
        start_word_idx: sfx.start_word_idx,
        end_dialogue_order_index: sfx.end_dialogue_order_index,
        end_word_idx: sfx.end_word_idx,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to delete sound effect");
    }

    return response.json();
  } catch (error) {
    console.error("Error deleting sound effect:", error);
    throw error;
  }
};

export const saveVoiceAssignments = async (
  voiceAssignments,
  email,
  timestamp,
) => {
  try {
    const response = await fetch(API_ENDPOINTS.SAVE_VOICE_ASSIGNMENTS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
        voice_assignments: voiceAssignments,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to save voice assignments");
    }

    return true;
  } catch (error) {
    console.error("Error saving voice assignments:", error);
    return false;
  }
};

export const deleteDialogueAudio = async (email, timestamp, orderIndex) => {
  try {
    const response = await fetch(API_ENDPOINTS.DELETE_DIALOGUE_AUDIO, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
        order_index: orderIndex,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to delete dialogue audio");
    }

    return await response.json();
  } catch (error) {
    console.error("Error deleting dialogue audio:", error);
    throw error;
  }
};

export const regenerateDialogueAudio = async (
  email,
  timestamp,
  dialogueIndex,
  dialogue,
) => {
  try {
    const response = await fetch(API_ENDPOINTS.REGENERATE_DIALOGUE_AUDIO, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
        dialogue_index: dialogueIndex,
        dialogue: dialogue,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to regenerate dialogue audio");
    }

    return await response.json();
  } catch (error) {
    console.error("Error regenerating dialogue audio:", error);
    throw error;
  }
};

export const applyVoiceChanges = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.APPLY_VOICE_CHANGES, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to apply voice changes");
    }

    return await response.json();
  } catch (error) {
    console.error("Error applying voice changes:", error);
    throw error;
  }
};

export const applySingleSfxVolumeChanges = async (
  email,
  timestamp,
  sfxIndices,
) => {
  try {
    console.log(
      `(applySingleSfxVolumeChanges) (debug super) ${email} ${timestamp} ${JSON.stringify(sfxIndices)}`,
    );
    const response = await fetch(
      API_ENDPOINTS.APPLY_SINGLE_SFX_VOLUME_CHANGES,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          timestamp,
          dialogue_order_index: sfxIndices.dialogueOrderIndex,
          start_word_idx: sfxIndices.startWordIdx,
          end_dialogue_order_index: sfxIndices.endDialogueOrderIndex,
          end_word_idx: sfxIndices.endWordIdx,
        }),
      },
    );
    return response.ok;
  } catch (error) {
    console.error("Error applying SFX volume changes:", error);
    return false;
  }
};

export const sendVerificationEmail = async (email) => {
  try {
    const response = await fetch(API_ENDPOINTS.SEND_VERIFICATION, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error sending verification email:", error);
    throw new Error("Failed to send verification email");
  }
};

export const verifyEmailToken = async (token) => {
  try {
    const response = await fetch(API_ENDPOINTS.VERIFY_EMAIL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.detail || "Verification failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error verifying email:", error);
    throw error;
  }
};

export const getUserOriginalQuota = async (email) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_USER_ORIGINAL_QUOTA}/${email}`,
    );
    const data = await response.json();
    return data.success ? data.original_quota : 10000; // Default to 10000 if request fails
  } catch (error) {
    console.error("Error fetching user original quota:", error);
    return 10000; // Default fallback
  }
};

export const applyBGMVolumeChange = async (email, timestamp, volume) => {
  try {
    const response = await fetch(API_ENDPOINTS.APPLY_BGM_VOLUME, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
        volume,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to apply BGM volume change");
    }

    return response.json();
  } catch (error) {
    console.error("Error applying BGM volume change:", error);
    throw error;
  }
};

export const deleteBGM = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.DELETE_BGM, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to delete BGM");
    }

    return response.json();
  } catch (error) {
    console.error("Error deleting BGM:", error);
    throw error;
  }
};

export const regenerateBackgroundMusic = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.REGENERATE_BGM, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });
    if (!response.ok) throw new Error("Failed to regenerate BGM");
    return await response.json();
  } catch (error) {
    console.error("Error regenerating BGM:", error);
    throw error;
  }
};

export const saveModifiedBGMDescription = async (
  email,
  timestamp,
  description,
) => {
  try {
    const response = await fetch(API_ENDPOINTS.SAVE_MODIFIED_BGM_DESCRIPTION, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
        description,
      }),
    });
    if (!response.ok) throw new Error("Failed to save BGM description");
    return await response.json();
  } catch (error) {
    console.error("Error saving BGM description:", error);
    throw error;
  }
};

export const applyModifiedBGM = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.APPLY_BGM, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });
    if (!response.ok) throw new Error("Failed to apply BGM");
    return await response.json();
  } catch (error) {
    console.error("Error applying BGM:", error);
    throw error;
  }
};
