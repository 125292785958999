import { Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import AlphaDemo from "./pages/AlphaDemo";
import VerificationPage from "./pages/VerificationPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import BlogPage from "./pages/BlogPage";
import VoiceGenerationTips from "./blogs/voice_generation_tips";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/demo" element={<AlphaDemo />} />
      <Route path="/verify" element={<VerificationPage />} />
      <Route path="/subscription" element={<SubscriptionPage />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/blog/voice-generation-tips" element={<VoiceGenerationTips />} />
    </Routes>
  );
}

export default App;
