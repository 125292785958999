import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { verifyEmailToken } from "../api/service";
import "../scss/verificationPage.scss";

function VerificationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [verificationStatus, setVerificationStatus] = useState("pending");
  const [error, setError] = useState("");
  const [verifiedEmail, setVerifiedEmail] = useState("");

  console.log("VerificationPage rendered", {
    location,
    search: location.search,
  });

  useEffect(() => {
    const verifyToken = async () => {
      const token = new URLSearchParams(location.search).get("token");
      console.log("Token from URL:", token);
      if (token) {
        try {
          const response = await verifyEmailToken(token);
          console.log("Verification response:", response);
          if (response.success) {
            setVerificationStatus("verified");
            setVerifiedEmail(response.email);
          } else {
            setVerificationStatus("failed");
            setError(response.message || "Verification failed");
          }
        } catch (err) {
          setVerificationStatus("failed");
          setError(err.message || "Verification failed");
        }
      }
    };

    verifyToken();
  }, [location]);

  const handleLoginClick = () => {
    navigate("/login", { state: { email: verifiedEmail } });
  };

  // If no token in URL, show "check your email" message
  if (!location.search.includes("token")) {
    return (
      <div className="verification-page">
        <div className="verification-container">
          <img
            className="product-title"
            src="/audiowizard.png"
            alt="AudioWizard"
          />
          <div className="verification-content">
            <h2>Check Your Email</h2>
            <p>
              We've sent a verification link to your email address. Please check
              your inbox and click the link to verify your account.
            </p>
            <p className="small-text">
              Don't see the email? Check your spam folder.
            </p>
          </div>
        </div>
      </div>
    );
  }

  // Show verification result
  return (
    <div className="verification-page">
      <div className="verification-container">
        <img
          className="product-title"
          src="/audiowizard.png"
          alt="AudioWizard"
        />
        <div className="verification-content">
          {verificationStatus === "verified" ? (
            <>
              <h2>Email Verified!</h2>
              <p>Your email has been successfully verified.</p>
              <button className="login-button" onClick={handleLoginClick}>
                Login to Continue
              </button>
            </>
          ) : (
            <>
              <h2>Verification Failed</h2>
              <p>{error || "Please try again or contact support."}</p>
              <button className="login-button" onClick={handleLoginClick}>
                Back to Login
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default VerificationPage;
