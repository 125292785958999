import React from "react";
import { useNavigate } from "react-router-dom";
import "../scss/blogPage.scss";

function BlogPage() {
  const navigate = useNavigate();

  return (
    <div className="blog-page">
      <div className="blog-header">
        <img
          className="product-title"
          src="/audiowizard.png"
          alt="AudioWizard"
          onClick={() => window.location.href = '/'}
        />
      </div>

      <div className="blog-content">
        <h1 className="blog-main-title">The Latest from our Blog</h1>
        <p className="blog-subtitle">
          Get the latest news and updates from our team. Explore tips and tricks,
          and discover new ways to unleash your creativity.
        </p>

        <div className="blog-cards">
          <div className="blog-card" onClick={() => navigate("/blog/voice-generation-tips")}>
            <div className="card-content">
              <div className="card-meta">
                <span className="date">Feb 15 2024</span>
                <span className="category">Tips & Tricks</span>
              </div>
              <h2>Voice Generation Tips & Tricks</h2>
              <p className="card-description">
                Learn essential techniques for generating high-quality voice content,
                from managing pauses and pacing to adding emphasis in your audio productions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogPage;
