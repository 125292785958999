import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { subscribeToMailerLite, sendVerificationEmail } from "../api/service";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../scss/loginPage.scss";

function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
    }
  }, [location]);

  const handleBackLanding = () => {
    navigate("/");
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const toggleMode = () => {
    setIsSignUp(!isSignUp);
    setError("");
    setSuccessMessage("");
    setPassword("");
    setConfirmPassword("");
  };

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    if (!email) {
      setError("Please enter your email");
      return;
    }

    if (!isValidEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }

    if (isSignUp) {
      // Registration mode
      if (password !== confirmPassword) {
        setError("Passwords do not match");
        return;
      }

      if (password.length < 6) {
        setError("Password must be at least 6 characters long");
        return;
      }
    }

    setIsLoading(true);
    try {
      if (isSignUp) {
        // First try to register
        const response = await subscribeToMailerLite(email, password, isSignUp);
        if (response.success) {
          // If registration successful, send verification email
          const verificationResponse = await sendVerificationEmail(email);
          if (verificationResponse.success) {
            setSuccessMessage(
              "Registration successful! Please check your email for verification link.",
            );
          } else {
            setError(
              "Registration completed but verification email failed to send. Please try again later.",
            );
          }
        } else {
          setError(response.message || "Registration failed");
        }
      } else {
        // Normal login flow
        const response = await subscribeToMailerLite(email, password);
        if (response.success) {
          localStorage.setItem("demoEmail", email);
          navigate("/demo");
        } else {
          setError(response.message || "Invalid email or password");
        }
      }
    } catch (error) {
      setError(error.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <img
          className="product-title"
          onClick={handleBackLanding}
          src="/audiowizard.png"
          alt="AudioWizard"
        />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => togglePasswordVisibility("password")}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          {isSignUp && (
            <div className="form-group">
              <label>Confirm Password</label>
              <div className="password-input-container">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="password-toggle"
                  onClick={() => togglePasswordVisibility("confirm")}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
          )}
          {error && <div className="error-message">{error}</div>}
          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : isSignUp ? "Register" : "Sign In"}
          </button>
          <div className="signup-link">
            <span>{isSignUp ? "Have an account? " : "No account? "}</span>
            <button
              type="button"
              className="signup-button"
              onClick={toggleMode}
            >
              {isSignUp ? "Sign In" : "Sign Up"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
