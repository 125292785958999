import React from "react";
import "./style.scss";

function VoiceGenerationTips() {
  return (
    <div className="blog-post-page">
      <div className="blog-header">
        <img
          className="product-title"
          src="/audiowizard.png"
          alt="AudioWizard"
          onClick={() => window.location.href = '/'}
        />
      </div>

      <article className="blog-post-content">
        <h1>Voice Generation Tips & Tricks</h1>

        <div className="post-meta">
          <span className="date">Feb 15 2024</span>
          <span className="category">Tips & Tricks</span>
        </div>

        <section>
          <h2>Pause</h2>
          <p>
            One trick that seems to provide the most consistence output - sans the above option -
            is a simple dash - or the em-dash —. You can even add multiple dashes such as -- --
            for a longer pause.
          </p>
          <div className="example">
            <p>"It - is - getting late."</p>
          </div>
          <p>
            Ellipsis ... can sometimes also work to add a pause between words but usually also adds
            some "hesitation" or "nervousness" to the voice that might not always fit.
          </p>
          <div className="example">
            <p>"I... yeah, I guess so..."</p>
          </div>
        </section>

        <section>
          <h2>Emotion</h2>
          <p>
            For emotion control in your voice generation, simply use the emotion control buttons
            available in the voice generation interface. These controls allow you to fine-tune
            the emotional tone of your generated audio, ensuring your content conveys the right feeling.
          </p>
        </section>

        <section>
          <h2>Pacing</h2>
          <p>
            To control the pacing of the speaker, you can use the same approach as in emotion,
            where you write in a style similar to that of a book. While it's not a perfect
            solution, it can help improve the pacing and ensure that the AI generates a voiceover
            at the right speed. With this technique, you can create high-quality voiceovers that
            are both customized and easy to listen to.
          </p>
          <div className="example">
            <p>"I wish you were right, I truly do, but you're not," he said slowly.</p>
          </div>
        </section>

        <section>
          <h2>Emphasis</h2>
          <p>
            Capitalize the words or adding exclamation marks after a word typically leads to
            the word being emphasized during voice generation.
          </p>
          <div className="example">
            <p>"I REALLY need you to listen to me!"</p>
            <p>"This is VERY important."</p>
            <p>"Please! Listen! To! Me!"</p>
          </div>
        </section>
      </article>
    </div>
  );
}

export default VoiceGenerationTips;
