import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import "../scss/alphaDemo.scss";
import {
  FaPlay,
  FaPause,
  FaShare,
  FaThumbsUp,
  FaVolumeDown,
  FaVolumeOff,
  FaVolumeUp,
  FaForward,
  FaBackward,
} from "react-icons/fa";
import {
  IoPlayCircle,
  IoPauseCircle,
} from "react-icons/io5";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Multitrack from "wavesurfer-multitrack";
import {
  transformTextToScreenplay,
  assignVoicesToCharacters,
  getVoiceGeneration,
  getWordAlignment,
  getMultitrack,
  getCombinedTrack,
  getSeparateTracksZip,
  getRemainingQuota,
  calculateQuotaUsage,
  subtractQuota,
  generateBGMDescription,
  generateBackgroundMusic,
  getBGMTrack,
  generateSoundEffectsDescription,
  getSoundEffectsDescription,
  saveModifiedSoundEffects,
  generateSoundEffects,
  getSoundEffectsTrack,
  saveModifiedScreenplay,
  getUserProjects,
  shareProject,
  getCommunityProjects,
  addReaction,
  getCommunityProject,
  getSoundEffectAudio,
  regenerateSingleSoundEffect,
  applyModifiedSoundEffects,
  deleteSoundEffect,
  saveVoiceAssignments,
  deleteDialogueAudio,
  regenerateDialogueAudio,
  applyVoiceChanges,
  applySingleVoiceLoudnessAndSpeedChanges,
  generateVoices,
  applySingleSfxVolumeChanges,
  getUserOriginalQuota,
  applyBGMVolumeChange,
  deleteBGM,
  getBGMAudio,
  regenerateBackgroundMusic,
  saveModifiedBGMDescription,
  applyModifiedBGM,
} from "../api/service";
import { API_ENDPOINTS } from "../api/api";

function LandingPage() {
  const navigate = useNavigate();
  const [quota, setQuota] = useState("N/A");
  const [voicePreviews, setVoicePreviews] = useState([]);
  const [voicePreviewsLoaded, setVoicePreviewsLoaded] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [soundEffectsData, setSoundEffectsData] = useState([]); // Move this up here
  const [selectedSoundEffect, setSelectedSoundEffect] = useState(null);
  const audioRef = useRef(null);

  // Add new state for alpha dialogues
  const [alphaDialogues, setAlphaDialogues] = useState([]);

  // Add new state for voice assignments
  const [characterVoiceAssignments, setCharacterVoiceAssignments] = useState(
    {},
  );
  const [activeCharacterForVoice, setActiveCharacterForVoice] = useState(null);

  const [modalSearchQuery, setModalSearchQuery] = useState("");

  const [alphaMultitrackRef, setAlphaMultitrackRef] = useState(null);
  const alphaMultitrackContainerRef = useRef(null);
  const [isPlayingAlphaMultitrack, setIsPlayingAlphaMultitrack] =
    useState(false);
  const [alphaMultitrackTracks, setAlphaMultitrackTracks] = useState([]);

  const [alphaAlignmentData, setAlphaAlignmentData] = useState([]);
  const [alphaCurrentTime, setAlphaCurrentTime] = useState(null);
  const alphaTimeIntervalRef = useRef(null);

  const [isGeneratingMultitrack, setIsGeneratingMultitrack] = useState(false);
  const [alphaMultitrackPaths, setAlphaMultitrackPaths] = useState(null);

  // Add state for tracking if BGM and SFX have been generated
  const [hasBGMGenerated, setHasBGMGenerated] = useState(false);
  const [hasSFXGenerated, setHasSFXGenerated] = useState(false);
  const [needsSfxAudioApply, setNeedsSfxAudioApply] = useState({});

  // Add these state variables to track progress
  const [furthestView, setFurthestView] = useState("input");
  const [completedSteps, setCompletedSteps] = useState([]);
  const [isTransforming, setIsTransforming] = useState(false);
  const [isAssigningVoices, setIsAssigningVoices] = useState(false);

  // Add these new state variables near the top of the component
  const [userEmail, setUserEmail] = useState(null);
  const [sessionTimestamp, setSessionTimestamp] = useState(null);
  const [originalDialogueStates, setOriginalDialogueStates] = useState({});
  const [originalSfxStates, setOriginalSfxStates] = useState({});

  // Add this state for quota error
  const [quotaError, setQuotaError] = useState(null);

  // Add these new state variables
  const [currentUserEmail, setCurrentUserEmail] = useState(null); // Current user's email
  const [currentSessionTimestamp, setCurrentSessionTimestamp] = useState(null); // Current user's session
  const [viewingSharedProject, setViewingSharedProject] = useState(null); // Track shared project info
  // Add this near the top of the file with other state declarations
  const [sfxVolumes, setSfxVolumes] = useState({});
  // Add oldSfxVolumes state to track old volumes for each sfx
  const [oldSfxVolumes, setOldSfxVolumes] = useState({});

  // Add this near the top with other state declarations
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  // Update handleVolumeChange to accept dialogueIndex
  const handleVolumeChange = async (event, dialogueIndex) => {
    if (!sessionTimestamp || !userEmail) {
      alert("Invalid session. Please restart the demo.");
      return;
    }

    const newVolume = event.target.value;

    console.log("(cool debug!) newVolume", newVolume);
    console.log("(cool debug!) dialogueIndex", dialogueIndex);
    // Update the specific dialogue's loudness
    setAlphaDialogues((prevDialogues) =>
      prevDialogues.map((dialogue, index) =>
        index === dialogueIndex ? { ...dialogue, volume: newVolume } : dialogue,
      ),
    );

    // Set needsVoiceApply to true when volume is changed
    setNeedsVoiceApply(true);
  };

  // Add new state for tracking max order index
  const [maxOrderIndex, setMaxOrderIndex] = useState(0);

  // Email validation function
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Update handleSfxVolumeChange to also update soundEffectsData
  const handleSfxVolumeChange = (e, sfx) => {
    const newVolume = parseFloat(e.target.value);
    const sfxId = `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;

    // Update volume in sfxVolumes state
    setSfxVolumes((prev) => ({
      ...prev,
      [sfxId]: newVolume,
    }));

    // Update volume in soundEffectsData
    setSoundEffectsData((prev) =>
      prev.map((effect) => {
        if (
          effect.dialogue_index === sfx.dialogue_index &&
          effect.start_word_idx === sfx.start_word_idx &&
          effect.end_dialogue_index === sfx.end_dialogue_index &&
          effect.end_word_idx === sfx.end_word_idx &&
          effect.dialogue_order_index === sfx.dialogue_order_index &&
          effect.start_dialogue_order_index === sfx.start_dialogue_order_index
        ) {
          return {
            ...effect,
            volume: newVolume,
          };
        }
        return effect;
      }),
    );
    console.log(
      `(handleSfxVolumeChange) (debug 1) soundEffectsData: ${JSON.stringify(
        soundEffectsData,
      )}`,
    );
  };

  const alertShown = useRef(false);

  // Update the useEffect for email initialization
  useEffect(() => {
    const email = localStorage.getItem("demoEmail");
    if (email && isValidEmail(email)) {
      setCurrentUserEmail(email);
      setUserEmail(email); // Keep this for backward compatibility
    } else {
      console.log("Email is invalid or missing");
      if (!alertShown.current) {
        alertShown.current = true;
        alert(
          "Please start the demo from https://audiowizard.ai/ with a valid email",
        );
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchQuota = async () => {
      const storedEmail = localStorage.getItem("demoEmail");
      if (storedEmail) {
        try {
          const response = await getRemainingQuota(storedEmail);
          console.log("Fetching quota response:", response);
          if (
            response.success &&
            response.quota !== undefined &&
            response.quota !== -1
          ) {
            setQuota(response.quota);
          } else {
            setQuota("N/A");
          }
        } catch (error) {
          console.error("Error fetching quota:", error);
          setQuota("N/A");
        }
      }
    };

    fetchQuota();
  }, []);

  // Modify handleTransformTextToScreenplay to set timestamp
  const handleTransformTextToScreenplay = async (inputText) => {
    if (!inputText.trim()) {
      alert("Please enter some text first");
      return;
    }

    if (!userEmail) {
      alert("Please start the demo from the landing page");
      navigate("/");
      return;
    }

    setIsTransforming(true);
    try {
      // Set UTC timestamp when starting the transformation
      const timestamp = new Date().toISOString();
      setCurrentSessionTimestamp(timestamp); // Add this line
      setSessionTimestamp(timestamp);

      const data = await transformTextToScreenplay(
        inputText,
        userEmail,
        timestamp,
      );
      setAlphaDialogues(data.screenplay);
      setActiveView("screenplay");
      setCompletedSteps([...completedSteps, "input"]);
      setFurthestView("screenplay");
    } catch (error) {
      console.error("Error transforming text:", error);
      alert("Failed to transform text. Please try again.");
    } finally {
      setIsTransforming(false);
    }
  };
  const filteredVoices = useMemo(() => {
    return voicePreviews.filter(
      (voice) =>
        voice.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        Object.values(voice.labels || {}).some((label) =>
          label.toLowerCase().includes(searchQuery.toLowerCase()),
        ),
    );
  }, [voicePreviews, searchQuery]);

  const filteredModalVoices = useMemo(() => {
    const filtered = voicePreviews.filter(
      (voice) =>
        voice.name.toLowerCase().includes(modalSearchQuery.toLowerCase()) ||
        Object.values(voice.labels || {}).some((label) =>
          label.toLowerCase().includes(modalSearchQuery.toLowerCase()),
        ),
    );

    console.log("Filtered Results:", filtered);
    return filtered;
  }, [voicePreviews, modalSearchQuery]);

  const handleBackLanding = () => {
    navigate("/");
  };

  const getHashFromString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32-bit integer
    }
    return Math.abs(hash);
  };

  const getColorFromSeed = (seed) => {
    const hash = getHashFromString(seed);
    const hue = hash % 360; // Use modulo to get a value between 0-360
    const saturation = 65; // Fixed saturation
    const lightness = 65; // Fixed lightness
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  // Add effect to preload voice data and audio files
  useEffect(() => {
    const preloadVoices = async () => {
      try {
        // First load the voice info JSON
        const response = await fetch("/assets/voice_previews/voice_info.json");
        const data = await response.json();

        // Create audio elements and preload all preview files
        const voicesWithPreload = await Promise.all(
          data.map(async (voice) => {
            // Update filename format to include voice ID
            const filename = `${voice.name}-${voice.voice_id}.mp3`;
            const audioPath = `/assets/voice_previews/audio/${filename}`;

            // Create and preload audio
            const audio = new Audio();
            audio.src = audioPath;

            // Wait for audio to be loaded
            try {
              await new Promise((resolve, reject) => {
                audio.addEventListener("loadeddata", resolve);
                audio.addEventListener("error", reject);
                // Start loading the audio
                audio.load();
              });
              console.log(`Loaded audio for ${voice.name}`);
            } catch (error) {
              console.error(`Failed to load audio for ${voice.name}:`, error);
            }

            return {
              ...voice,
              filename,
              audioPath,
              color: getColorFromSeed(voice.name),
            };
          }),
        );

        setVoicePreviews(voicesWithPreload);
        setVoicePreviewsLoaded(true);
        console.log("All voice previews loaded");
      } catch (error) {
        console.error("Error loading voice previews:", error);
        setVoicePreviewsLoaded(false);
      }
    };

    preloadVoices();
  }, []);

  // Handle play/pause for voice preview
  const handlePlayVoice = (voice) => {
    if (audioRef.current) {
      if (selectedVoice?.filename === voice.filename && isPlaying) {
        // If clicking the same voice that's playing, pause it
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        // If clicking a different voice or a paused voice, play it
        if (selectedVoice?.filename !== voice.filename) {
          audioRef.current.src = `/assets/voice_previews/audio/${voice.filename}`;
        }
        audioRef.current.play();
        setSelectedVoice(voice);
        setIsPlaying(true);
      }
    }
  };

  // Handle audio ending
  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  // Add state for active view and text input
  const [activeView, setActiveView] = useState("input");
  const [inputText, setInputText] = useState("");
  const [textError, setTextError] = useState("");
  const WORD_LIMIT = 2000;

  // Add function to handle text change
  const handleTextChange = (e) => {
    const text = e.target.value;
    const wordCount = text.trim().split(/\s+/).length;

    if (wordCount > WORD_LIMIT) {
      setTextError(`Text exceeds ${WORD_LIMIT} words limit`);
    } else {
      setTextError("");
      setInputText(text);
    }
  };

  // Add this after the handleTextChange function
  const handleMusicDescriptionChange = (e) => {
    setMusicDescription(e.target.value);
  };

  // Modify handleVoiceAssignment to use email and timestamp
  const handleVoiceAssignment = async () => {
    if (!sessionTimestamp || !userEmail) {
      alert("Invalid session. Please restart the demo.");
      return;
    }

    setIsAssigningVoices(true);
    try {
      // First save the modified screenplay
      const saveSuccess = await saveModifiedScreenplay(
        alphaDialogues,
        userEmail,
        sessionTimestamp,
      );

      if (!saveSuccess) {
        throw new Error("Failed to save modified screenplay");
      }

      // Then proceed with voice assignment
      const data = await assignVoicesToCharacters(userEmail, sessionTimestamp);
      const voiceAssignments = {};
      Object.entries(data.voice_assignments).forEach(([character, voiceId]) => {
        const matchingVoice = voicePreviews.find(
          (voice) => voice.voice_id === voiceId,
        );
        if (matchingVoice) {
          voiceAssignments[character] = matchingVoice;
        }
      });
      setCharacterVoiceAssignments(voiceAssignments);
      setActiveView("voice-assignment");
      setCompletedSteps([...completedSteps, "screenplay"]);
      setFurthestView("voice-assignment");
    } catch (error) {
      console.error("Error assigning voices:", error);
      alert("Failed to assign voices. Please try again.");
    } finally {
      setIsAssigningVoices(false);
    }
  };

  // Add this function to handle play/pause for alpha multitrack
  const handlePlayPauseAlphaMultitrack = useCallback(() => {
    if (alphaMultitrackRef) {
      if (alphaMultitrackRef.isPlaying()) {
        alphaMultitrackRef.pause();
        setIsPlayingAlphaMultitrack(false);
      } else {
        alphaMultitrackRef.play();
        setIsPlayingAlphaMultitrack(true);
      }
    }
  }, [alphaMultitrackRef]);

  // Add this function near your other utility functions
  const getAlphaTrackColor = (character) => {
    const colors = [
      { wave: "#8B5CF6", progress: "#6D28D9" }, // Violet
      { wave: "#F59E0B", progress: "#D97706" }, // Amber
      { wave: "#EC4899", progress: "#DB2777" }, // Pink
      { wave: "#10B981", progress: "#059669" }, // Emerald
      { wave: "#3B82F6", progress: "#2563EB" }, // Blue
      { wave: "#EF4444", progress: "#DC2626" }, // Red
      { wave: "#6366F1", progress: "#4F46E5" }, // Indigo
      { wave: "#14B8A6", progress: "#0D9488" }, // Teal
      { wave: "#F472B6", progress: "#DB2777" }, // Pink
      { wave: "#84CC16", progress: "#65A30D" }, // Lime
    ];

    // Create a deterministic but random index based on character name
    const hash = character.split("").reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);

    const index = Math.abs(hash) % colors.length;
    return colors[index];
  };

  // Add this function to handle word click in alpha multitrack
  const handleAlphaWordClick = useCallback(
    (startTime) => {
      if (alphaMultitrackRef) {
        alphaMultitrackRef.setTime(startTime);
      }
    },
    [alphaMultitrackRef],
  );

  // Update the isAlphaWordPlaying function to use absolute times
  const isAlphaWordPlaying = useCallback(
    (wordStartTime, wordEndTime) => {
      return (
        alphaCurrentTime !== null &&
        alphaCurrentTime >= wordStartTime &&
        alphaCurrentTime <= wordEndTime
      );
    },
    [alphaCurrentTime],
  );

  // Update renderAlphaMultitrackScreenplay to remove SFX highlights and icons
  const renderAlphaMultitrackScreenplay = useCallback(() => {
    if (!alphaDialogues.length) return null;

    return (
      <div className="alpha-screenplay-content">
        {alphaDialogues.map((dialogue, dialogueIndex) => {
          // Handle both old and new format
          const character = dialogue.character || Object.keys(dialogue)[0];
          const text = dialogue.text || Object.values(dialogue)[0];
          const alignment = alphaAlignmentData[dialogueIndex];

          let content;
          if (alignment) {
            content = alignment.map((word, wordIndex) => {
              const isCurrentlyPlaying = isAlphaWordPlaying(
                word.absoluteStartTime,
                word.absoluteEndTime,
              );

              return (
                <span
                  key={`${dialogueIndex}-${wordIndex}`}
                  className={`alpha-word ${isCurrentlyPlaying ? "current-word" : ""}`}
                  onClick={() => handleAlphaWordClick(word.absoluteStartTime)}
                >
                  {word.word}
                </span>
              );
            });
          } else {
            content = <span>{text}</span>;
          }

          return (
            <div key={dialogueIndex} className="dialogue-item">
              <span className="character">
                {character.endsWith(":") ? character : `${character}:`}
              </span>
              {content}
            </div>
          );
        })}
      </div>
    );
  }, [
    alphaDialogues,
    alphaAlignmentData,
    isAlphaWordPlaying,
    handleAlphaWordClick,
  ]);

  // Add this helper function to get audio duration
  const getAudioDuration = async (url) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio();
      audio.addEventListener("loadedmetadata", () => {
        resolve(audio.duration);
      });
      audio.addEventListener("error", reject);
      audio.src = url;
    });
  };

  // First, let's extract the word alignment loading logic into a separate function
  const loadWordAlignments = async (
    email,
    timestamp,
    dialogues,
    isShared = false,
  ) => {
    try {
      const alignments = new Array(dialogues.length);
      let cumulativeTime = 0;

      // First pass: Load all alignments using order_index
      for (let i = 0; i < dialogues.length; i++) {
        const dialogue = dialogues[i];
        const orderIndex = dialogue.order_index ?? i;

        if (i > 0) {
          // Get audio duration from previous dialogue's order_index
          const prevDialogue = dialogues[i - 1];
          const prevOrderIndex = prevDialogue.order_index ?? i - 1;
          const audioBlob = await getVoiceGeneration(
            email,
            timestamp,
            prevOrderIndex,
            isShared,
          );
          const audioUrl = URL.createObjectURL(audioBlob);
          const duration = await getAudioDuration(audioUrl);
          URL.revokeObjectURL(audioUrl);
          cumulativeTime += duration;
        }

        // Load alignment data using order_index
        const data = await getWordAlignment(
          email,
          timestamp,
          orderIndex,
          isShared,
        );

        const processedData = data.map((word) => ({
          ...word,
          absoluteStartTime: word.start_time + cumulativeTime,
          absoluteEndTime: word.end_time + cumulativeTime,
        }));

        // Store alignment at the natural index position
        alignments[i] = processedData;
      }

      return alignments;
    } catch (error) {
      console.error("Error loading word alignments:", error);
      throw error;
    }
  };

  // Then update the useEffect for loading alignments
  useEffect(() => {
    const loadAlphaAlignments = async () => {
      // Don't load alignments if we're viewing a shared project
      // since handleCommunityProjectSelect handles that
      if (viewingSharedProject) return;

      if (!alphaMultitrackPaths || !userEmail || !sessionTimestamp) return;

      try {
        const alignments = await loadWordAlignments(
          userEmail,
          sessionTimestamp,
          alphaDialogues,
        );
        setAlphaAlignmentData(alignments);
      } catch (error) {
        console.error("Error loading alpha alignments:", error);
      }
    };

    if (alphaDialogues.length > 0 && alphaMultitrackPaths) {
      loadAlphaAlignments();
    }
  }, [
    alphaDialogues,
    alphaMultitrackPaths,
    userEmail,
    sessionTimestamp,
    viewingSharedProject,
  ]);

  // Add effect to track current time in alpha multitrack
  useEffect(() => {
    if (alphaMultitrackRef) {
      if (isPlayingAlphaMultitrack) {
        alphaTimeIntervalRef.current = setInterval(() => {
          const currentTime = alphaMultitrackRef.getCurrentTime();
          setAlphaCurrentTime(currentTime);
        }, 100);
      } else {
        if (alphaTimeIntervalRef.current) {
          clearInterval(alphaTimeIntervalRef.current);
        }
      }

      return () => {
        if (alphaTimeIntervalRef.current) {
          clearInterval(alphaTimeIntervalRef.current);
        }
      };
    }
  }, [isPlayingAlphaMultitrack, alphaMultitrackRef]);

  // Update the handleGenerateVoice function
  const handleGenerateVoice = async () => {
    if (!sessionTimestamp || !userEmail) {
      alert("Invalid session. Please restart the demo.");
      return;
    }

    // Check if all characters have voice assignments
    const unassignedCharacters = alphaDialogues
      .map((dialogue) => dialogue.character)
      .filter(
        (character, index, self) =>
          // Get unique characters
          self.indexOf(character) === index &&
          // Filter to only those without voice assignments
          !characterVoiceAssignments[character],
      );

    if (unassignedCharacters.length > 0) {
      alert(
        `Please assign voices to the following characters before generating: ${unassignedCharacters.join(", ")}`,
      );
      return;
    }

    try {
      setIsGeneratingMultitrack(true);
      setQuotaError(null);

      // Step 1: Get current quota
      const quotaResponse = await getRemainingQuota(userEmail);
      const currentQuota = quotaResponse.quota;

      // Step 2: Calculate needed quota
      console.log(`debug alphaDialogues: ${JSON.stringify(alphaDialogues)}`);
      const neededQuota = await calculateQuotaUsage(alphaDialogues);

      // Step 3: Check if we have enough quota
      if (neededQuota > currentQuota) {
        setQuotaError(
          `Insufficient quota. You need ${neededQuota} characters but only have ${currentQuota} remaining. Please contact support to increase your quota.`,
        );
        return;
      }

      // Step 4: Subtract quota
      const subtractSuccess = await subtractQuota(userEmail, neededQuota);
      if (!subtractSuccess) {
        setQuotaError("Failed to update quota. Please try again.");
        return;
      }

      // Update displayed quota
      setQuota(currentQuota - neededQuota);

      // Continue with voice generation
      const voiceAssignments = {};
      Object.entries(characterVoiceAssignments).forEach(
        ([character, voice]) => {
          voiceAssignments[character] = voice.voice_id;
        },
      );

      console.log(
        `debug voiceAssignments: ${JSON.stringify(voiceAssignments)}`,
      );

      // Save modified screenplay again to ensure consistency
      const screenplaySaveSuccess = await saveModifiedScreenplay(
        alphaDialogues,
        userEmail,
        sessionTimestamp,
      );

      if (!screenplaySaveSuccess) {
        throw new Error("Failed to save modified screenplay");
      }

      // Continue with voice generation
      const generateVoicesSuccess = await generateVoices(
        userEmail,
        sessionTimestamp,
      );
      if (!generateVoicesSuccess) {
        throw new Error("Failed to generate voices");
      }

      // Store the original states
      const newOriginalStates = {};
      alphaDialogues.forEach((dialogue, index) => {
        newOriginalStates[index] = {
          character: dialogue.character,
          text: dialogue.text,
          emotion: dialogue.emotion,
          voiceId: characterVoiceAssignments[dialogue.character]?.voice_id,
          order_index: dialogue.order_index ?? index,
          volume: dialogue.volume ?? 1.0,
          speed: dialogue.speed ?? 1.0,
        };
      });
      setOriginalDialogueStates(newOriginalStates);

      await checkDialogueAudioAvailability();
    } catch (error) {
      console.error("Error generating voices:", error);
      setQuotaError("Failed to generate audio. Please try again.");
    } finally {
      setIsGeneratingMultitrack(false);
    }
  };

  // Update isViewAvailable function
  const isViewAvailable = (view) => {
    // Input view is always available
    if (view === "input") return true;

    // Gallery, projects, and community are always available
    if (["gallery", "projects", "community"].includes(view)) {
      return true;
    }

    // For all other views, check if their corresponding data exists
    switch (view) {
      case "screenplay":
        return alphaDialogues && alphaDialogues.length > 0;

      case "voice-assignment":
        return Object.keys(characterVoiceAssignments).length > 0;

      case "music-description":
        // Available if screenplay exists
        return musicDescription !== "";

      case "sound-effects":
        // Available if screenplay exists
        return soundEffectsData.length > 0;

      case "multitrack-audio":
        return alphaMultitrackPaths !== null;

      default:
        return false;
    }
  };

  // Add state for dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Add this helper function to handle downloads
  const downloadBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  // Update the download handlers
  const handleCombinedDownload = async () => {
    try {
      console.log("Starting combined download...");
      const blob = await getCombinedTrack(userEmail, sessionTimestamp);
      console.log("Got blob:", blob);
      downloadBlob(blob, "combined_audio.mp3");
      setIsDropdownOpen(false);
    } catch (error) {
      console.error("Error downloading combined track:", error);
      alert("Failed to download combined track. Please try again.");
    }
  };

  const handleSeparateDownload = async () => {
    try {
      console.log("Starting separate tracks download...");
      const blob = await getSeparateTracksZip(userEmail, sessionTimestamp);
      console.log("Got blob:", blob);
      downloadBlob(blob, "separate_tracks.zip");
      setIsDropdownOpen(false);
    } catch (error) {
      console.error("Error downloading separate tracks:", error);
      alert("Failed to download separate tracks. Please try again.");
    }
  };

  // Add this after the activeView state declaration
  const [musicDescription, setMusicDescription] = useState("");

  // Add this to your state declarations
  const [isGeneratingBGM, setIsGeneratingBGM] = useState(false);

  // Update the handleGenerateBGMDescription function
  const handleGenerateBGMDescription = async () => {
    if (!sessionTimestamp || !userEmail) {
      alert("Invalid session. Please restart the demo.");
      return;
    }

    try {
      setIsGeneratingBGM(true);
      const description = await generateBGMDescription(
        userEmail,
        sessionTimestamp,
      );
      setMusicDescription(description);
      // Only add music-description to furthestView
      setFurthestView(
        furthestView.includes("music-description")
          ? furthestView
          : furthestView + ",music-description",
      );
      setActiveView("music-description");
    } catch (error) {
      console.error("Error generating BGM description:", error);
      alert("Failed to generate BGM description. Please try again.");
    } finally {
      setIsGeneratingBGM(false);
    }
  };

  // Add new state for BGM generation
  const [isGeneratingMusic, setIsGeneratingMusic] = useState(false);

  // Keep only one initialization function
  const initializeMultitrack = useCallback(async () => {
    if (
      !alphaMultitrackContainerRef.current ||
      !alphaMultitrackPaths ||
      !alphaDialogues.length
    ) {
      console.log("Missing required data for multitrack initialization");
      return;
    }

    console.log("Initializing multitrack with dialogues:", alphaDialogues);

    // Destroy existing multitrack if it exists
    if (alphaMultitrackRef) {
      alphaMultitrackRef.destroy();
    }

    const defaultHeight = 55;
    // Update character extraction to handle both formats
    const characters = [
      ...new Set(
        alphaDialogues.map((dialogue) => {
          // Handle both old and new format
          const character = dialogue.character || Object.keys(dialogue)[0];
          return character.replace(":", "");
        }),
      ),
    ];

    const tracks = [];
    const isShared = viewingSharedProject !== null;

    // Use shared project info if available, otherwise use current user's info
    const projectEmail = viewingSharedProject
      ? viewingSharedProject.email
      : currentUserEmail;
    const projectTimestamp = viewingSharedProject
      ? viewingSharedProject.timestamp
      : currentSessionTimestamp;

    // Add character tracks
    for (const character of characters) {
      try {
        const colors = getAlphaTrackColor(character);
        console.log(`Fetching track for character: ${character}`); // Debug log

        const audioBlob = await getMultitrack(
          projectEmail,
          projectTimestamp,
          character,
          isShared,
        );

        const audioUrl = URL.createObjectURL(audioBlob);
        tracks.push({
          id: character,
          url: audioUrl,
          options: {
            waveColor: colors.wave,
            progressColor: colors.progress,
            height: defaultHeight,
          },
        });
      } catch (error) {
        console.error(
          `Failed to load track for character: ${character}`,
          error,
        );
        // Continue with other tracks even if one fails
        continue;
      }
    }

    // Try to add BGM track if it has been generated
    console.log(`(debug 1) hasBGMGenerated: ${hasBGMGenerated}`);
    try {
      const bgmBlob = await getBGMTrack(
        projectEmail, // Use project email
        projectTimestamp, // Use project timestamp
        isShared,
      );
      console.log("BGM track found and loaded");
      const bgmUrl = URL.createObjectURL(bgmBlob);
      tracks.push({
        id: "bgm",
        url: bgmUrl,
        options: {
          waveColor: "#8B5CF6",
          progressColor: "#6D28D9",
          height: defaultHeight,
        },
      });
    } catch (error) {
      console.log("No BGM track available yet");
    }

    // Try to add SFX track if it has been generated
    try {
      const sfxBlob = await getSoundEffectsTrack(
        projectEmail, // Use project email
        projectTimestamp, // Use project timestamp
        isShared,
      );
      const sfxUrl = URL.createObjectURL(sfxBlob);
      tracks.push({
        id: "Sound Effects",
        url: sfxUrl,
        options: {
          waveColor: "#10B981",
          progressColor: "#059669",
          height: defaultHeight,
        },
      });
    } catch (error) {
      console.log("No sound effects track available yet");
    }

    setAlphaMultitrackTracks(tracks);

    const multitrack = Multitrack.create(tracks, {
      container: alphaMultitrackContainerRef.current,
      minPxPerSec: 50,
      cursorWidth: 2,
      cursorColor: "#D72F21",
      trackBackground: "#f0f0f0",
      trackBorderColor: "#ddd",
    });

    setAlphaMultitrackRef(multitrack);

    multitrack.on("play", () => setIsPlayingAlphaMultitrack(true));
    multitrack.on("pause", () => setIsPlayingAlphaMultitrack(false));
    multitrack.on("finish", () => setIsPlayingAlphaMultitrack(false));
  }, [
    alphaDialogues,
    alphaMultitrackRef,
    currentSessionTimestamp,
    currentUserEmail,
    alphaMultitrackPaths,
    hasBGMGenerated,
    hasSFXGenerated,
    viewingSharedProject,
  ]);

  useEffect(() => {
    // Initialize multitrack
    if (
      activeView === "multitrack-audio" &&
      alphaMultitrackPaths &&
      !alphaMultitrackRef &&
      !isGeneratingMusic &&
      alphaDialogues.length > 0
    ) {
      console.log("Initializing multitrack...");
      initializeMultitrack();
    }

    // Cleanup when leaving multitrack view
    return () => {
      if (activeView !== "multitrack-audio" && alphaMultitrackRef) {
        console.log("Cleaning up multitrack...");
        alphaMultitrackRef.destroy();
        setAlphaMultitrackRef(null);
        setAlphaMultitrackTracks([]);
      }
    };
  }, [
    activeView,
    alphaMultitrackRef,
    alphaMultitrackPaths,
    isGeneratingMusic,
    initializeMultitrack,
    alphaDialogues,
  ]);

  // Also update the cleanup in the useEffect to destroy the multitrack when leaving the view
  useEffect(() => {
    if (activeView !== "multitrack-audio" && alphaMultitrackRef) {
      alphaMultitrackRef.destroy();
      setAlphaMultitrackRef(null);
    }
  }, [activeView, alphaMultitrackRef]);

  // Update handleGenerateBGM function
  const handleGenerateBGM = async () => {
    if (!sessionTimestamp || !userEmail) {
      alert("Invalid session. Please restart the demo.");
      return;
    }

    try {
      setIsGeneratingMusic(true);
      setQuotaError(null);

      // Step 1: Get current quota
      const quotaResponse = await getRemainingQuota(userEmail);
      const currentQuota = quotaResponse.quota;

      // Step 2: Calculate needed quota - use the same logic as voice generation
      const neededQuota = musicDescription.length;

      // Step 3: Check if we have enough quota
      if (neededQuota > currentQuota) {
        setQuotaError(
          `Insufficient quota. You need ${neededQuota} characters but only have ${currentQuota} remaining. Please contact support to increase your quota.`,
        );
        return;
      }

      // Step 4: Subtract quota
      const subtractSuccess = await subtractQuota(userEmail, neededQuota);
      if (!subtractSuccess) {
        setQuotaError("Failed to update quota. Please try again.");
        return;
      }

      // Update displayed quota
      setQuota(currentQuota - neededQuota);

      console.log(`(debug 3) currentQuota: ${currentQuota}`);
      // Continue with BGM generation
      await generateBackgroundMusic(userEmail, sessionTimestamp);

      // Reset volume to 1.0
      setBgmVolume(1.0);
      setHasBGMGenerated(true);

    } catch (error) {
      console.error("Error generating background music:", error);
      alert("Failed to generate background music. Please try again.");
    } finally {
      setIsGeneratingMusic(false);
    }
  };

  // Add this after the musicDescription state
  const [isGeneratingSFX, setIsGeneratingSFX] = useState(false);

  // Add ref for the right panel
  const sfxContentRef = useRef(null);

  // Rename the original function but keep its implementation exactly the same
  const handleGenerateSFXDescription = async () => {
    if (!sessionTimestamp || !userEmail) {
      alert("Invalid session. Please restart the demo.");
      return;
    }

    try {
      setIsGeneratingSFX(true);
      await generateSoundEffectsDescription(userEmail, sessionTimestamp);

      // Load all sound effects descriptions at once
      const sfxData = await getSoundEffectsDescription(
        userEmail,
        sessionTimestamp,
      );
      console.log(`(debug) sfxData: ${JSON.stringify(sfxData)}`);

      // Set the original sfx states when generating sound effects description
      const newOriginalSfxStates = {};
      sfxData.forEach((sfx) => {
        const sfxId = `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;
        newOriginalSfxStates[sfxId] = {
          ...sfx,
          volume: sfx.volume || 1.0,
        };
      });
      setOriginalSfxStates(newOriginalSfxStates);

      setSoundEffectsData(sfxData);

      // Only add sound-effects to furthestView
      setFurthestView(
        furthestView.includes("sound-effects")
          ? furthestView
          : furthestView + ",sound-effects",
      );
      setActiveView("sound-effects");
    } catch (error) {
      console.error("Error generating sound effects:", error);
      alert("Failed to generate sound effects. Please try again.");
    } finally {
      setIsGeneratingSFX(false);
    }
  };

  const handleGenerateSFXAudio = async () => {
    try {
      setIsGeneratingSFX(true);
      setQuotaError(null);

      // Step 1: Get current quota
      const quotaResponse = await getRemainingQuota(userEmail);
      const currentQuota = quotaResponse.quota;

      // Step 2: Calculate needed quota - sum up all SFX descriptions
      const neededQuota = soundEffectsData.reduce(
        (total, sfx) => total + sfx.foley_description.length,
        0,
      );

      // Step 3: Check if we have enough quota
      if (neededQuota > currentQuota) {
        setQuotaError(
          `Insufficient quota. You need ${neededQuota} characters but only have ${currentQuota} remaining. Please contact support to increase your quota.`,
        );
        return;
      }

      // Step 4: Subtract quota
      const subtractSuccess = await subtractQuota(userEmail, neededQuota);
      if (!subtractSuccess) {
        setQuotaError("Failed to update quota. Please try again.");
        return;
      }

      // Update displayed quota
      setQuota(currentQuota - neededQuota);

      // Save the sound effects directly - no need for conversion since we're already using order_indices
      await saveModifiedSoundEffects(
        userEmail,
        sessionTimestamp,
        soundEffectsData, // Already using order_indices
      );

      // Generate sound effects
      const success = await generateSoundEffects(userEmail, sessionTimestamp);

      if (!success) {
        throw new Error("Failed to generate sound effects");
      }

      // Set SFX as generated
      setHasSFXGenerated(true);
      // Reset needsSfxAudioApply since we just generated fresh audio
      setNeedsSfxAudioApply({});

      // Check audio availability after regeneration
      await checkSfxAudioAvailability();
    } catch (error) {
      console.error("Error in sound effects generation:", error);
      alert("Failed to generate sound effects. Please try again.");
    } finally {
      setIsGeneratingSFX(false);
    }
  };

  // Update handleSfxIconClick to use 4 indices
  const handleSfxIconClick = (dialogue_index, sfx) => {
    console.log(`(debug 11) dialogue_index: ${dialogue_index}`);
    console.log(`(debug 11) sfx: ${JSON.stringify(sfx)}`);
    setSelectedSoundEffect({
      sfx,
    });

    const targetId = `sfx-${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;
    const targetElement = document.getElementById(targetId);
    console.log(`(debug 11) targetId: ${targetId}`);
    console.log(`(debug 11) document: ${document}`);
    console.log(`(debug 11) targetElement: ${targetElement}`);
    console.log(`(debug 11) sfxContentRef.current: ${sfxContentRef.current}`);

    if (targetElement && sfxContentRef.current) {
      const container = sfxContentRef.current;
      const targetTop = targetElement.offsetTop;
      const containerHeight = container.clientHeight;

      console.log(`(debug 11) super fucking debug !!!`);
      container.scrollTo({
        top: targetTop - containerHeight / 2 + targetElement.clientHeight / 2,
        behavior: "smooth",
      });
    }
  };

  // Add this helper function to get word positions
  const getWordPositions = (text) => {
    const words = [];
    const regex = /\S+/g;
    let match;
    while ((match = regex.exec(text)) !== null) {
      words.push({
        word: match[0],
        start: match.index,
        end: match.index + match[0].length,
      });
    }
    return words;
  };

  // Add these new state variables near the top with other states
  const [selectedText, setSelectedText] = useState(null);
  const [showSfxPopup, setShowSfxPopup] = useState(false);
  const [sfxPopupPosition, setSfxPopupPosition] = useState({ x: 0, y: 0 });
  const [showSfxDescriptionModal, setShowSfxDescriptionModal] = useState(false);
  const [newSfxDescription, setNewSfxDescription] = useState("");

  // Add these new state variables near the top with other states
  const [playingSfxId, setPlayingSfxId] = useState(null);
  const [sfxAudioRef, setSfxAudioRef] = useState(null);

  // Add this function to handle play/pause for individual sound effects
  const handlePlaySfx = async (sfx) => {
    console.log(
      `(we are in the front end in handlePlaySfx function right now) (debug 15) sfx: ${JSON.stringify(sfx)}`,
    );
    const sfxId = `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;

    // If already playing this sound effect, pause it
    if (playingSfxId === sfxId && sfxAudioRef) {
      sfxAudioRef.pause();
      setPlayingSfxId(null);
      return;
    }

    // If playing a different sound effect, stop it
    if (sfxAudioRef) {
      sfxAudioRef.pause();
    }

    try {
      console.log(
        `(handlePlaySfx) (debug 12) soundEffectsData: ${JSON.stringify(soundEffectsData)}`,
      );
      // Save modified sound effects first
      const saveSuccess = await saveModifiedSoundEffects(
        userEmail,
        sessionTimestamp,
        soundEffectsData,
      );

      if (!saveSuccess) {
        console.error("Failed to save modified sound effects");
        return;
      }

      // Apply volume changes before getting the audio
      const success = await applySingleSfxVolumeChanges(
        userEmail,
        sessionTimestamp,
        {
          dialogueOrderIndex: sfx.dialogue_order_index,
          startWordIdx: sfx.start_word_idx,
          endDialogueOrderIndex: sfx.end_dialogue_order_index,
          endWordIdx: sfx.end_word_idx,
        },
      );

      if (!success) {
        console.error("Failed to apply SFX volume change");
        return;
      }

      // Use shared project email and timestamp if viewing a shared project
      const playEmail = viewingSharedProject
        ? viewingSharedProject.email
        : userEmail;
      const playTimestamp = viewingSharedProject
        ? viewingSharedProject.timestamp
        : sessionTimestamp;
      const isShared = Boolean(viewingSharedProject);

      console.log(
        `Playing SFX for ${playEmail}/${playTimestamp}/${sfx.dialogue_index}/${sfx.start_word_idx} (shared: ${isShared})`,
      );

      console.log(
        `(debug 12) ${playEmail} ${playTimestamp} ${sfx.dialogue_index} ${sfx.start_word_idx} ${sfx.end_dialogue_index} ${sfx.end_word_idx} ${isShared}`,
      );
      const blob = await getSoundEffectAudio(
        playEmail,
        playTimestamp,
        sfx.dialogue_order_index,
        sfx.start_word_idx,
        sfx.end_dialogue_order_index,
        sfx.end_word_idx,
        isShared,
      );
      const audio = new Audio(URL.createObjectURL(blob));

      audio.addEventListener("ended", () => {
        setPlayingSfxId(null);
      });

      setSfxAudioRef(audio);
      setPlayingSfxId(sfxId);
      audio.play();
    } catch (error) {
      console.error("Error playing sound effect:", error);
    }
  };

  // Add this function after other handlers
  const handleTextSelection = (e) => {
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();

    if (selectedText) {
      const range = selection.getRangeAt(0);

      // Find the containing dialogue elements for start and end of selection
      const startDialogueElement = findParentDialogue(range.startContainer);
      const endDialogueElement = findParentDialogue(range.endContainer);

      // Only verify that we found valid dialogue elements
      if (!startDialogueElement || !endDialogueElement) {
        console.log("Invalid selection - couldn't find dialogue elements");
        return; // Invalid selection
      }

      // Get dialogue indices
      const startDialogueIndex = parseInt(
        startDialogueElement.getAttribute("data-dialogue-index"),
      );
      const endDialogueIndex = parseInt(
        endDialogueElement.getAttribute("data-dialogue-index"),
      );

      // Ensure indices are valid and in correct order
      if (
        isNaN(startDialogueIndex) ||
        isNaN(endDialogueIndex) ||
        startDialogueIndex > endDialogueIndex
      ) {
        console.log(
          "Invalid selection - dialogue indices invalid or out of order",
        );
        return;
      }

      console.log(`(debug 9) startDialogueIndex: ${startDialogueIndex}`);
      console.log(`(debug 9) endDialogueIndex: ${endDialogueIndex}`);

      // Get all words in each dialogue
      const startWords = Array.from(
        startDialogueElement.querySelectorAll(".alpha-word"),
      );
      const endWords = Array.from(
        endDialogueElement.querySelectorAll(".alpha-word"),
      );

      console.log(`(debug 9) startWords: ${startWords}`);
      console.log(`(debug 9) endWords: ${endWords}`);

      // Find start and end word containers
      const startContainer = range.startContainer.parentElement;
      const endContainer = range.endContainer.parentElement;

      // Find word indices within their respective dialogues
      let startWordIdx = -1;
      let endWordIdx = -1;

      startWords.forEach((word, idx) => {
        if (word === startContainer || word.contains(startContainer)) {
          startWordIdx = idx;
        }
      });

      endWords.forEach((word, idx) => {
        if (word === endContainer || word.contains(endContainer)) {
          endWordIdx = idx;
        }
      });

      if (startWordIdx !== -1 && endWordIdx !== -1) {
        // Clear the original selection
        selection.removeAllRanges();

        // Create a new range for the full words
        const newRange = document.createRange();

        // Set start point
        newRange.setStartBefore(startWords[startWordIdx].firstChild);

        // Set end point
        newRange.setEndAfter(endWords[endWordIdx].lastChild);

        selection.addRange(newRange);

        // Collect all selected text and sentences
        const selectedSentences = [];
        const selectedWords = [];

        // Process dialogues from start to end
        for (let i = startDialogueIndex; i <= endDialogueIndex; i++) {
          const dialogue = alphaDialogues[i];
          // Use dialogue.text instead of Object.values(dialogue)[0]
          const fullText = dialogue.text;
          const wordsInfo = getWordPositions(fullText);

          let selectedWordsForDialogue;
          if (i === startDialogueIndex && i === endDialogueIndex) {
            // Single dialogue selection
            selectedWordsForDialogue = wordsInfo
              .slice(startWordIdx, endWordIdx + 1)
              .map((w) => w.word);
            selectedWords.push(...selectedWordsForDialogue);
            selectedSentences.push(selectedWordsForDialogue.join(" ")); // Only add selected portion
          } else if (i === startDialogueIndex) {
            // First dialogue in multi-dialogue selection
            selectedWordsForDialogue = wordsInfo
              .slice(startWordIdx)
              .map((w) => w.word);
            selectedWords.push(...selectedWordsForDialogue);
            selectedSentences.push(selectedWordsForDialogue.join(" ")); // Only add selected portion
          } else if (i === endDialogueIndex) {
            // Last dialogue in multi-dialogue selection
            selectedWordsForDialogue = wordsInfo
              .slice(0, endWordIdx + 1)
              .map((w) => w.word);
            selectedWords.push(...selectedWordsForDialogue);
            selectedSentences.push(selectedWordsForDialogue.join(" ")); // Only add selected portion
          } else {
            // Middle dialogues - take all words
            selectedWordsForDialogue = wordsInfo.map((w) => w.word);
            selectedWords.push(...selectedWordsForDialogue);
            selectedSentences.push(selectedWordsForDialogue.join(" ")); // Add full dialogue for middle sections
          }
        }

        // Get the last selected word's position for popup
        const lastWord = endWords[endWordIdx];
        const rect = lastWord.getBoundingClientRect();

        setSelectedText({
          text: selectedWords.join(" "),
          startDialogueIndex,
          startWordIdx,
          endDialogueIndex,
          endWordIdx,
          sentences: selectedSentences,
        });

        // Position popup right next to the last word
        setSfxPopupPosition({
          x: rect.right + 5,
          y: rect.top + rect.height / 2,
        });
        setShowSfxPopup(true);
      }
    } else {
      setShowSfxPopup(false);
    }
  };

  // Update this helper function to handle text nodes
  const findParentDialogue = (node) => {
    // If we get a text node, start with its parent element
    let current = node.nodeType === Node.TEXT_NODE ? node.parentElement : node;

    console.log(
      `(debug 9) node.nodeType === Node.TEXT_NODE: ${node.nodeType === Node.TEXT_NODE}`,
    );
    while (current && !current.hasAttribute("data-dialogue-index")) {
      current = current.parentElement;
    }
    return current;
  };

  // Add this function to handle adding new SFX
  const handleAddNewSfx = () => {
    setShowSfxPopup(false);
    setShowSfxDescriptionModal(true);
  };

  // Update handleConfirmNewSfx to store order_indices from the start
  const handleConfirmNewSfx = () => {
    if (newSfxDescription.trim() && selectedText) {
      // Create a single SFX entry that spans multiple dialogues if needed
      const newSfx = {
        // UI indices
        dialogue_index: selectedText.startDialogueIndex,
        end_dialogue_index: selectedText.endDialogueIndex,
        // Backend indices
        dialogue_order_index:
          alphaDialogues[selectedText.startDialogueIndex].order_index,
        end_dialogue_order_index:
          alphaDialogues[selectedText.endDialogueIndex].order_index,
        // Word indices (same for both UI and backend)
        start_word_idx: selectedText.startWordIdx,
        end_word_idx: selectedText.endWordIdx,
        foley_description: newSfxDescription,
        sentence: selectedText.text,
      };

      setSoundEffectsData([...soundEffectsData, newSfx]);
      setShowSfxDescriptionModal(false);
      setNewSfxDescription("");
      setSelectedText(null);
    }
  };

  // Add new state for regeneration
  const [regeneratingSfx, setRegeneratingSfx] = useState(null);

  // Update the setSfxDescriptionModified function to check both description and volume
  const isSfxModified = (sfx) => {
    const sfxId = `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;
    const originalSfx = originalSfxStates[sfxId];

    // If the sfx is not in the originalSfxStates, it is newly added and no audio is available
    if (!originalSfx) return true;

    return sfx.foley_description !== originalSfx.foley_description;
  };

  // Add useEffect to track changes and update modified status
  useEffect(() => {
    const newNeedsSfxAudioApply = {};
    soundEffectsData.forEach((sfx) => {
      const sfxId = `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;
      const sfxIsModified = isSfxModified(sfx);
      newNeedsSfxAudioApply[sfxId] = sfxIsModified;
      if (sfxIsModified) {
        // If the sfx is modified and we are not keeping track of the old volume,
        // set the volume to 1.0 and add the sfx to oldSfxVolumes
        if (!oldSfxVolumes[sfxId]) {
          const newOldSfxVolumes = { ...oldSfxVolumes };
          newOldSfxVolumes[sfxId] = sfxVolumes[sfxId];
          setOldSfxVolumes(newOldSfxVolumes);
          sfxVolumes[sfxId] = 1.0;
        }
      } else {
        // If the sfx is not modified, but we were keeping track of the old volume,
        // set the volume to the old volume and remove the sfx from oldSfxVolumes
        if (oldSfxVolumes[sfxId]) {
          sfxVolumes[sfxId] = oldSfxVolumes[sfxId];
          const newOldSfxVolumes = { ...oldSfxVolumes };
          delete newOldSfxVolumes[sfxId];
          setOldSfxVolumes(newOldSfxVolumes);
        }
      }
    });
    setNeedsSfxAudioApply(newNeedsSfxAudioApply);
  }, [soundEffectsData, originalSfxStates]);

  // Update handleRegenerateSfx to use stored order_indices
  const handleRegenerateSfx = async (sfx) => {
    // Use dialogue_index for UI
    const sfxId = `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;

    try {
      setRegeneratingSfx(sfxId);

      // Save without conversion
      await saveModifiedSoundEffects(
        userEmail,
        sessionTimestamp,
        soundEffectsData,
      );

      // Regenerate using existing order_indices
      await regenerateSingleSoundEffect(
        userEmail,
        sessionTimestamp,
        sfx.dialogue_order_index,
        sfx.start_word_idx,
        sfx.end_dialogue_order_index,
        sfx.end_word_idx,
        sfx.foley_description,
      );

      // Reset volume to 1.0 after regenerating
      setSfxVolumes((prev) => ({
        ...prev,
        [sfxId]: 1.0,
      }));

      await checkSfxAudioAvailability();

      setOriginalSfxStates((prev) => ({
        ...prev,
        [sfxId]: {
          ...sfx,
        },
      }));
    } catch (error) {
      console.error("Error regenerating sound effect:", error);
      alert("Failed to regenerate sound effect. Please try again.");
    } finally {
      setRegeneratingSfx(null);
    }
  };

  // Update the render function to include the regenerate button
  const renderSoundEffectsView = () => {
    return (
      <div className="alpha-sfx-view">
        <PanelGroup direction="horizontal">
          <Panel minSize={20} defaultSize={50}>
            <div className="alpha-sfx-screenplay-panel">
              <div className="alpha-sfx-screenplay-header">
                <h3 className="alpha-sfx-screenplay-title">📖 Screenplay</h3>
                <h4 className="alpha-sfx-screenplay-subtitle">
                  🖱️ Select Text to Add Sound Effects
                </h4>
              </div>
              <div className="alpha-sfx-screenplay-content">
                <div className="screenplay-grid">
                  {alphaDialogues.map((dialogue, dialogueIndex) => {
                    // Update to use new dialogue format
                    const character = dialogue.character;
                    const text = dialogue.text;
                    const words = getWordPositions(text);

                    return (
                      <div key={dialogueIndex} className="screenplay-row">
                        <div className="character-cell">
                          {character.endsWith(":") ? character : `${character}`}
                        </div>
                        <div
                          className="dialogue-cell"
                          data-dialogue-index={dialogueIndex}
                          onMouseUp={(e) =>
                            !isReadOnly && handleTextSelection(e)
                          }
                          title={
                            !isReadOnly
                              ? "🖱️ Select text to add sound effects"
                              : ""
                          }
                        >
                          {words.map((wordInfo, wordIndex) => {
                            // Existing sound effect logic remains unchanged
                            const sfxForWord = soundEffectsData.find((sfx) => {
                              // Single dialogue case
                              if (
                                !sfx.end_dialogue_index ||
                                sfx.dialogue_index === sfx.end_dialogue_index
                              ) {
                                return (
                                  sfx.dialogue_index === dialogueIndex &&
                                  wordIndex >= sfx.start_word_idx &&
                                  wordIndex <= sfx.end_word_idx
                                );
                              }

                              // First dialogue of multi-dialogue selection
                              if (sfx.dialogue_index === dialogueIndex) {
                                return wordIndex >= sfx.start_word_idx;
                              }

                              // Last dialogue of multi-dialogue selection
                              if (sfx.end_dialogue_index === dialogueIndex) {
                                return wordIndex <= sfx.end_word_idx;
                              }

                              // Middle dialogues
                              return (
                                dialogueIndex > sfx.dialogue_index &&
                                dialogueIndex < sfx.end_dialogue_index
                              );
                            });

                            const isEndOfSoundEffect = soundEffectsData.some(
                              (sfx) =>
                                dialogueIndex === sfx.end_dialogue_index &&
                                wordIndex === sfx.end_word_idx,
                            );

                            const soundEffectAtEnd = isEndOfSoundEffect
                              ? soundEffectsData.find(
                                  (sfx) =>
                                    dialogueIndex === sfx.end_dialogue_index &&
                                    wordIndex === sfx.end_word_idx,
                                )
                              : null;

                            return (
                              <span
                                key={wordIndex}
                                className={`alpha-word ${sfxForWord ? "has-sfx" : ""}`}
                              >
                                {wordInfo.word}
                                {soundEffectAtEnd && (
                                  <span
                                    className="sfx-icon"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleSfxIconClick(
                                        dialogueIndex,
                                        soundEffectAtEnd,
                                      );
                                    }}
                                  >
                                    🎵
                                  </span>
                                )}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Panel>
          <PanelResizeHandle />
          <Panel minSize={20} defaultSize={50}>
            <div className="alpha-sfx-container">
              <div className="alpha-sfx-title-section">
                <h3 className="alpha-sfx-title">🔊 Sound Effects</h3>
                <div className="sfx-buttons">
                  <button
                    className={`generate-sfx-button ${isGeneratingSFX ? "loading" : ""}`}
                    onClick={handleGenerateSFXAudio}
                    disabled={isReadOnly || isGeneratingSFX}
                  >
                    {isGeneratingSFX ? (
                      <>
                        <div className="loading-spinner" />
                        <span className="button-text">Generating...</span>
                      </>
                    ) : (
                      <>
                        <span className="button-icon">🔊</span>
                        <span className="button-text">Generate SFX</span>
                        <span className="button-arrow">→</span>
                      </>
                    )}
                  </button>
                  <button
                    className={`apply-sfx-button ${isApplyingSFX ? "loading" : ""}`}
                    onClick={handleApplySFX}
                    disabled={
                      isReadOnly ||
                      isApplyingSFX ||
                      Object.values(needsSfxAudioApply).some((status) => status) // if any sfx is modified, disable the apply button
                    }
                  >
                    {isApplyingSFX ? (
                      <>
                        <div className="loading-spinner" />
                        <span className="button-text">Applying...</span>
                      </>
                    ) : (
                      <>
                        <span className="button-icon">🎨</span>
                        <span className="button-text">Apply SFX</span>
                        <span className="button-arrow">→</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
              <div className="alpha-sfx-content" ref={sfxContentRef}>
                {soundEffectsData.map((sfx) => (
                  <div
                    key={`sfx-${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`}
                    id={`sfx-${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`}
                    className={`sfx-description ${
                      selectedSoundEffect?.sfx === sfx ? "selected" : ""
                    }`}
                  >
                    <div className="sfx-description-header">
                      <div className="sfx-header-left">
                        <span className="sfx-icon">🎵</span>
                        <span className="sfx-label">
                          Sound Effect {soundEffectsData.indexOf(sfx) + 1}
                        </span>
                      </div>
                      <div className="sfx-header-right">
                        <div className="volume-slider-container">
                          <div className="volume-wrapper">
                            <div className="volume-controls">
                              <div className="volume-icon-wrapper">
                                {sfxVolumes[
                                  `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`
                                ] > 2.5 ? (
                                  <FaVolumeUp className="volume-icon" />
                                ) : sfxVolumes[
                                    `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`
                                  ] < 0.5 ? (
                                  <FaVolumeOff className="volume-icon" />
                                ) : (
                                  <FaVolumeDown className="volume-icon" />
                                )}
                              </div>
                              <input
                                type="range"
                                min="0.1"
                                max="3.0"
                                step="0.1"
                                value={
                                  sfxVolumes[
                                    `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`
                                  ] || 1.0
                                }
                                onChange={(e) => handleSfxVolumeChange(e, sfx)}
                                className="volume-slider"
                                disabled={
                                  (() => {
                                    const sfxId = `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;
                                    return (
                                      regeneratingSfx === sfxId ||
                                      needsSfxAudioApply[sfxId] ||
                                      Boolean(viewingSharedProject)
                                    );
                                  })()
                                }
                              />
                            </div>
                            <div className="volume-multiplier">
                              {(
                                sfxVolumes[
                                  `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`
                                ] || 1.0
                              ).toFixed(1)}
                              x
                            </div>
                          </div>
                        </div>
                        <button
                          className={`sfx-play-button ${
                            availableSfxAudios.has(
                              `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`,
                            )
                              ? "active"
                              : "disabled"
                          }`}
                          onClick={() => handlePlaySfx(sfx)}
                          disabled={
                            (() => {
                              const sfxId = `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;
                              const isRegenerating = regeneratingSfx === sfxId;
                              const isNotAvailable = !availableSfxAudios.has(sfxId);
                              const needsApply = needsSfxAudioApply[sfxId];
                              const isSharedView = Boolean(viewingSharedProject);

                              console.log(`SFX ${sfxId} disabled conditions:`, {
                                isRegenerating,
                                isNotAvailable,
                                needsApply,
                                isSharedView,
                                availableSfxAudios: Array.from(availableSfxAudios),
                              });

                              return isRegenerating || isNotAvailable || needsApply || isSharedView;
                            })()
                          }
                        >
                          {playingSfxId ===
                          `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}` ? (
                            <IoPauseCircle size={24} />
                          ) : (
                            <IoPlayCircle size={24} />
                          )}
                        </button>

                        {/* Add regenerate button */}
                        <button
                          className={`sfx-regenerate-button ${
                            regeneratingSfx ===
                            `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`
                              ? "loading"
                              : ""
                          }`}
                          onClick={() => handleRegenerateSfx(sfx)}
                          disabled={
                            isReadOnly ||
                            regeneratingSfx ===
                              `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`
                          }
                        >
                          {regeneratingSfx ===
                          `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}` ? (
                            <div className="loading-spinner" />
                          ) : (
                            "🔄"
                          )}
                        </button>

                        <button
                          className="sfx-delete-button"
                          onClick={async (e) => {
                            e.stopPropagation();
                            try {
                              // Delete from backend first
                              await deleteSoundEffect(
                                userEmail,
                                sessionTimestamp,
                                sfx,
                              );

                              // If successful, update local state
                              const newSoundEffectsData =
                                soundEffectsData.filter((s) => s !== sfx);
                              setSoundEffectsData(newSoundEffectsData);

                              const newOriginalSfxStates = {};
                              newSoundEffectsData.forEach((sfx) => {
                                const sfxId = `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;
                                newOriginalSfxStates[sfxId] = {
                                  ...sfx,
                                  volume: sfx.volume || 1.0,
                                };
                              });
                              setOriginalSfxStates(newOriginalSfxStates);

                              if (selectedSoundEffect?.sfx === sfx) {
                                setSelectedSoundEffect(null);
                              }

                              // Update available audios
                              await checkSfxAudioAvailability();
                            } catch (error) {
                              console.error(
                                "Error deleting sound effect:",
                                error,
                              );
                              alert(
                                "Failed to delete sound effect. Please try again.",
                              );
                            }
                          }}
                          disabled={isReadOnly}
                          title={
                            isReadOnly
                              ? "Delete not available in shared projects"
                              : "Delete sound effect"
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                    <p className="sfx-description-text">
                      <textarea
                        className="sfx-description-input"
                        value={sfx.foley_description}
                        disabled={isReadOnly}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                        onChange={(e) => {
                          const newSoundEffectsData = soundEffectsData.map(
                            (s) =>
                              s === sfx
                                ? { ...s, foley_description: e.target.value }
                                : s,
                          );
                          setSoundEffectsData(newSoundEffectsData);
                        }}
                      />
                    </p>
                    <p className="sfx-sentence">"{sfx.sentence}"</p>
                  </div>
                ))}
              </div>
            </div>
          </Panel>
        </PanelGroup>

        {/* Only show these if not in read-only mode */}
        {!isReadOnly && (
          <>
            {showSfxPopup && (
              <div
                className="sfx-popup"
                style={{
                  position: "fixed",
                  left: `${sfxPopupPosition.x}px`,
                  top: `${sfxPopupPosition.y}px`,
                }}
              >
                <button onClick={handleAddNewSfx}>🎵 Add SFX</button>
              </div>
            )}

            {showSfxDescriptionModal && (
              <div className="sfx-description-modal">
                <div className="modal-content">
                  <h3>Add Sound Effect</h3>
                  <div className="selected-text-container">
                    <p className="selected-text">
                      <span className="selected-text-label">
                        Selected text:
                      </span>
                      <span className="selected-text-content">
                        "{selectedText?.text}"
                      </span>
                    </p>
                  </div>
                  <textarea
                    value={newSfxDescription}
                    onChange={(e) => setNewSfxDescription(e.target.value)}
                    placeholder="Describe the sound effect..."
                  />
                  <div className="modal-actions">
                    <button
                      className="cancel-button"
                      onClick={() => {
                        setShowSfxDescriptionModal(false);
                        setNewSfxDescription("");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="confirm-button"
                      onClick={handleConfirmNewSfx}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  // Add these new state variables near the top of the component
  const [projects, setProjects] = useState([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);

  // Add this effect to load projects when email is available
  useEffect(() => {
    const loadProjects = async () => {
      if (!userEmail) return;

      setIsLoadingProjects(true);
      try {
        const response = await getUserProjects(userEmail);
        setProjects(response.projects);
      } catch (error) {
        console.error("Error loading projects:", error);
      } finally {
        setIsLoadingProjects(false);
      }
    };

    loadProjects();
  }, [userEmail]);

  // Add this function to handle project selection
  const handleProjectSelect = async (project) => {
    try {
      console.log("Loading private project with timestamp:", project.timestamp);

      // Clear all existing states first
      clearProjectStates();

      // Set timestamps
      const timestamp = project.timestamp;
      setSessionTimestamp(timestamp);
      setCurrentSessionTimestamp(timestamp);

      // Reset shared project flag
      setViewingSharedProject(null);
      setIsReadOnly(false);

      const response = await fetch(
        `${API_ENDPOINTS.GET_PROJECT_DATA}/${userEmail}/${timestamp}?t=${Date.now()}`,
      );
      const data = await response.json();

      if (data.input_text) {
        setInputText(data.input_text);
      }

      if (data.screenplay) {
        const formattedScreenplay = data.screenplay.map((dialogue) => {
          if (dialogue.character && dialogue.text) {
            return dialogue;
          }
          const [character, text] = Object.entries(dialogue)[0];
          return {
            character: character,
            text: text,
            emotion: "default",
            volume: 1.0,
          };
        });
        setAlphaDialogues(formattedScreenplay);

        // Store original dialogue states after screenplay and voice assignments are set
        const newOriginalStates = {};
        formattedScreenplay.forEach((dialogue, index) => {
          newOriginalStates[index] = {
            character: dialogue.character,
            text: dialogue.text,
            emotion: dialogue.emotion,
            voiceId: data.voice_assignments?.[dialogue.character],
            order_index: dialogue.order_index ?? index,
            volume: dialogue.volume ?? 1.0,
          };
        });
        setOriginalDialogueStates(newOriginalStates);
      }

      if (data.voice_assignments) {
        const voiceAssignments = {};
        Object.entries(data.voice_assignments).forEach(
          ([character, voiceId]) => {
            const matchingVoice = voicePreviews.find(
              (voice) => voice.voice_id === voiceId,
            );
            if (matchingVoice) {
              voiceAssignments[character] = matchingVoice;
            }
          },
        );
        setCharacterVoiceAssignments(voiceAssignments);
      }

      if (data.bgm_description) {
        const bgmData = JSON.parse(data.bgm_description);
        setMusicDescription(bgmData.description);
        setBgmVolume(bgmData.volume || 1.0);
      }

      if (data.sound_effects) {
        // Create a mapping of volume settings
        const sfxVolumes = {};
        data.sound_effects.forEach((sfx) => {
          const sfxId = `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;
          sfxVolumes[sfxId] = sfx.volume || 1.0; // Default to 1.0 if volume not specified
        });

        // Set both the sound effects data and volumes
        setSoundEffectsData(data.sound_effects);

        // Set the original sfx states when selecting a project
        let originalSfxStates = {};
        data.sound_effects.forEach((sfx) => {
          const sfxId = `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;
          originalSfxStates[sfxId] = {
            ...sfx,
            volume: sfx.volume || 1.0,
          };
        });
        setOriginalSfxStates(originalSfxStates);

        setSfxVolumes(sfxVolumes);
      }

      // Set flags for BGM and SFX if they exist
      const hasBGM = await checkBGMExists(userEmail, project.timestamp);
      const hasSFX = await checkSFXExists(userEmail, project.timestamp);
      console.log(`(SUPER DEBUG) hasBGM: ${hasBGM}`);
      console.log(`(SUPER DEBUG) hasSFX: ${hasSFX}`);
      setHasBGMGenerated(hasBGM);
      setHasSFXGenerated(hasSFX);

      // Initialize multitrack if available
      if (await checkMultitrackExists(userEmail, project.timestamp)) {
        setAlphaMultitrackPaths({});
        setActiveView("multitrack-audio");
      } else {
        setActiveView("input");
      }
    } catch (error) {
      console.error("Error loading project:", error);
      alert("Failed to load project. Please try again.");
    }
  };

  // Add this new helper function to clear all project-related states
  const clearProjectStates = () => {
    // Clear multitrack states
    if (alphaMultitrackRef) {
      alphaMultitrackRef.destroy();
    }
    setAlphaMultitrackRef(null);
    setAlphaMultitrackTracks([]);
    setAlphaMultitrackPaths(null);
    setAlphaCurrentTime(null);
    setIsPlayingAlphaMultitrack(false);

    // Clear screenplay and dialogue states
    setAlphaDialogues([]);
    setAlphaAlignmentData([]);

    // Clear voice assignments
    setCharacterVoiceAssignments({});

    // Clear BGM states
    setMusicDescription("");
    setHasBGMGenerated(false);

    // Clear SFX states
    setSoundEffectsData([]);
    setHasSFXGenerated(false);

    // Clear other states
    setCompletedSteps([]);
    setFurthestView("input");
    setQuotaError(null);
  };

  // Helper functions to check if files exist
  const checkBGMExists = async (email, timestamp, isShared = false) => {
    try {
      const response = await fetch(
        `${API_ENDPOINTS.GET_BGM_AUDIO}/${email}/${timestamp}?is_shared=${isShared}&t=${Date.now()}`,
      );
      return response.ok;
    } catch {
      return false;
    }
  };

  const checkSFXExists = async (email, timestamp, isShared = false) => {
    try {
      const response = await fetch(
        `${API_ENDPOINTS.GET_SFX_TRACK}/${email}/${timestamp}?is_shared=${isShared}&t=${Date.now()}`,
      );
      return response.ok;
    } catch {
      return false;
    }
  };

  // Update the checkMultitrackExists function
  const checkMultitrackExists = async (email, timestamp, isShared = false) => {
    try {
      const response = await fetch(
        `${API_ENDPOINTS.GET_COMBINED_TRACK}/${email}/${timestamp}?is_shared=${isShared}&t=${Date.now()}`,
      );
      return response.ok;
    } catch {
      return false;
    }
  };

  // Add new state variables
  const [communityProjects, setCommunityProjects] = useState([]);
  const [isLoadingCommunity, setIsLoadingCommunity] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  // Add new state for sharing status
  const [isSharing, setIsSharing] = useState(false);

  // Add new function to handle sharing
  const handleShareProject = async () => {
    try {
      setIsSharing(true); // Start sharing state
      console.log("Sharing project...");
      console.log("currentUserEmail:", currentUserEmail);
      console.log("currentSessionTimestamp:", currentSessionTimestamp);

      if (!currentUserEmail || !currentSessionTimestamp) {
        alert("Cannot share project - session information missing");
        return;
      }

      await shareProject(currentUserEmail, currentSessionTimestamp);

      console.log("Project shared successfully!");
      // Refresh community projects
      await loadCommunityProjects();

      // Switch to community view
      setActiveView("community");
    } catch (error) {
      console.error("Error sharing project:", error);
      alert("Failed to share project. Please try again.");
    } finally {
      setIsSharing(false); // End sharing state
    }
  };

  // Add state for tracking reaction loading
  const [loadingReactions, setLoadingReactions] = useState({});

  // Update handleReaction
  const handleReaction = async (projectEmail, projectTimestamp) => {
    try {
      // Set loading state for this specific project
      setLoadingReactions((prev) => ({
        ...prev,
        [`${projectEmail}-${projectTimestamp}`]: true,
      }));

      const response = await addReaction(
        currentUserEmail,
        projectEmail,
        projectTimestamp,
        "thumbsUp",
      );

      if (response.success) {
        // Update the local state to reflect the change
        setCommunityProjects((prevProjects) =>
          prevProjects.map((project) => {
            if (
              project.email === projectEmail &&
              project.timestamp === projectTimestamp
            ) {
              return {
                ...project,
                reactions: response.reactionCount,
                hasReacted: response.hasReacted,
              };
            }
            return project;
          }),
        );
      }
    } catch (error) {
      console.error("Error adding reaction:", error);
      alert("Failed to add reaction. Please try again.");
    } finally {
      // Clear loading state for this project
      setLoadingReactions((prev) => ({
        ...prev,
        [`${projectEmail}-${projectTimestamp}`]: false,
      }));
    }
  };

  // Add function to load community projects
  const loadCommunityProjects = async () => {
    setIsLoadingCommunity(true);
    try {
      const response = await getCommunityProjects(currentUserEmail);
      setCommunityProjects(response.projects);
    } catch (error) {
      console.error("Error loading community projects:", error);
    } finally {
      setIsLoadingCommunity(false);
    }
  };

  // Add useEffect to load community projects
  useEffect(() => {
    if (activeView === "community") {
      loadCommunityProjects();
    }
  }, [activeView]);

  // Add this function after other handler functions
  const handleCommunityProjectSelect = async (project) => {
    try {
      console.log("Loading shared project:", project);

      // Clear all existing states first
      clearProjectStates();

      // Set read-only mode and store shared project info
      setIsReadOnly(true);
      setViewingSharedProject({
        email: project.email,
        timestamp: project.timestamp,
      });

      // Load project data
      const data = await getCommunityProject(project.email, project.timestamp);
      console.log(
        `(handleCommunityProjectSelect) debug 1 ${JSON.stringify(data)}`,
      );

      // Set input text if available
      if (data.input_text) {
        setInputText(data.input_text);
      }

      // Set states in specific order
      if (data.screenplay) {
        // Convert old format to new format if needed
        const formattedScreenplay = data.screenplay.map((dialogue) => {
          // Check if it's already in new format
          if (dialogue.character && dialogue.text) {
            return dialogue;
          }
          // Convert old format to new format
          const [character, text] = Object.entries(dialogue)[0];
          return {
            character: character,
            text: text,
            emotion: "default", // Default emotion for old format
          };
        });
        setAlphaDialogues(formattedScreenplay);

        // Store original dialogue states after screenplay and voice assignments are set
        const newOriginalStates = {};
        formattedScreenplay.forEach((dialogue, index) => {
          newOriginalStates[index] = {
            character: dialogue.character,
            text: dialogue.text,
            emotion: dialogue.emotion,
            voiceId: data.voice_assignments?.[dialogue.character],
            order_index: dialogue.order_index ?? index,
            volume: dialogue.volume ?? 1.0,
          };
        });
        setOriginalDialogueStates(newOriginalStates);

        // Load word alignments using shared function
        try {
          const alignments = await loadWordAlignments(
            project.email,
            project.timestamp,
            formattedScreenplay,
            true,
          );
          setAlphaAlignmentData(alignments);
        } catch (error) {
          console.error("Error loading word alignments:", error);
        }
      }

      if (data.voice_assignments) {
        const voiceAssignments = {};
        Object.entries(data.voice_assignments).forEach(
          ([character, voiceId]) => {
            const matchingVoice = voicePreviews.find(
              (voice) => voice.voice_id === voiceId,
            );
            if (matchingVoice) voiceAssignments[character] = matchingVoice;
          },
        );
        setCharacterVoiceAssignments(voiceAssignments);
      }

      // Set BGM and SFX states
      if (data.bgm_description) {
        console.log("Setting BGM data");
        const bgmData = JSON.parse(data.bgm_description);
        setMusicDescription(bgmData.description);
        setBgmVolume(bgmData.volume || 1.0);
        console.log(`(debug 2) hasBGMGenerated`);
        setHasBGMGenerated(
          await checkBGMExists(project.email, project.timestamp, true),
        );
      }

      if (data.sound_effects) {
        console.log("Setting SFX data");
        setSoundEffectsData(data.sound_effects);

        // Set the original sfx states when selecting a community project
        let originalSfxStates = {};
        data.sound_effects.forEach((sfx) => {
          const sfxId = `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;
          originalSfxStates[sfxId] = {
            ...sfx,
            volume: sfx.volume || 1.0,
          };
        });
        setOriginalSfxStates(originalSfxStates);

        setHasSFXGenerated(
          await checkSFXExists(project.email, project.timestamp, true),
        );
      }

      // Initialize multitrack if available
      if (await checkMultitrackExists(project.email, project.timestamp, true)) {
        console.log("Shared project has multitrack, initializing...");
        setAlphaMultitrackPaths({});
        setActiveView("multitrack-audio");
      } else {
        // Start at input view to show the original text
        setActiveView("input");
        // setActiveView('multitrack-audio');
      }
    } catch (error) {
      console.error("Error loading community project:", error);
      alert("Failed to load project. Please try again.");
    }
  };

  // Add new state for applying SFX
  const [isApplyingSFX, setIsApplyingSFX] = useState(false);

  // Add handler for applying SFX
  const handleApplySFX = async () => {
    setIsApplyingSFX(true);
    try {
      await applyModifiedSoundEffects(userEmail, sessionTimestamp);
      setHasSFXGenerated(true);

      // Clean up existing multitrack and audio elements
      if (alphaMultitrackRef) {
        alphaMultitrackRef.destroy();
        setAlphaMultitrackRef(null);
      }

      // Clear all cached tracks and audio elements
      setAlphaMultitrackTracks([]);

      // Initialize with new tracks including updated SFX
      await initializeMultitrack();

      setActiveView("multitrack-audio"); // Set to multitrack view if it exists
    } catch (error) {
      console.error("Error applying sound effects:", error);
      alert("Failed to apply sound effects. Please try again.");
    } finally {
      setIsApplyingSFX(false);
    }
  };

  // Add new state to track available audio files
  const [availableSfxAudios, setAvailableSfxAudios] = useState(new Set());

  // Add function to check audio availability
  const checkSfxAudioAvailability = async () => {
    console.log(
      `(we are in the front end in checkSfxAudioAvailability function right now)`,
    );
    if (!soundEffectsData) {
      console.log("No sound effects data available");
      return;
    }

    const checkEmail = viewingSharedProject
      ? viewingSharedProject.email
      : userEmail;
    const checkTimestamp = viewingSharedProject
      ? viewingSharedProject.timestamp
      : sessionTimestamp;
    const isShared = Boolean(viewingSharedProject);

    if (!checkEmail || !checkTimestamp) {
      console.log("Missing email or timestamp for SFX availability check");
      return;
    }

    const newAvailableAudios = new Set();

    for (const sfx of soundEffectsData) {
      try {
        // Use order_indices for API call
        const response = await fetch(
          `${API_ENDPOINTS.GET_SFX_AUDIO}/${checkEmail}/${checkTimestamp}/${sfx.dialogue_order_index}/${sfx.start_word_idx}/${sfx.end_dialogue_order_index}/${sfx.end_word_idx}?is_shared=${isShared}&t=${Date.now()}`,
        );
        if (response.ok) {
          // Use dialogue_index for UI
          const audioId = `${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_dialogue_index}-${sfx.end_word_idx}`;
          newAvailableAudios.add(audioId);
        }
      } catch (error) {
        console.error("Error checking audio availability:", error);
      }
    }

    console.log(
      `(checkSfxAudioAvailability) (debug 100) newAvailableAudios: ${newAvailableAudios}`,
    );
    setAvailableSfxAudios(newAvailableAudios);
  };

  useEffect(() => {
    console.log(
      `(useEffect function) (debug 101) soundEffectsData: ${JSON.stringify(soundEffectsData)}`,
    );
    console.log(
      `(useEffect function) (debug 102) hasSFXGenerated: ${hasSFXGenerated}`,
    );
    if (soundEffectsData && soundEffectsData.length > 0) {
      checkSfxAudioAvailability();
    }
  }, [soundEffectsData, hasSFXGenerated]);

  // Update useEffect to initialize maxOrderIndex when loading dialogues
  useEffect(() => {
    if (alphaDialogues && alphaDialogues.length > 0) {
      console.log(
        "Current dialogues with order indices:",
        alphaDialogues.map((d) => ({
          character: d.character,
          order_index: d.order_index,
        })),
      );
      const maxIndex = Math.max(
        ...alphaDialogues.map((d) => d.order_index ?? -1),
      );
      setMaxOrderIndex(maxIndex >= 0 ? maxIndex : 0);
    }
  }, [alphaDialogues]);

  // Update the dialogue insertion logic
  const handleInsertDialogue = (index) => {
    const newDialogues = [...alphaDialogues];
    const newOrderIndex = maxOrderIndex + 1;
    console.log(
      `(we are in the front end in handleInsertDialogue function right now) (debug 20) index: ${newOrderIndex}`,
    );

    newDialogues.splice(index, 0, {
      character: "",
      text: "",
      emotion: "default",
      prompt: null,
      order_index: newOrderIndex, // Assign new order_index
      volume: 1.0,
      speed: 1.0,
    });

    setAlphaDialogues(newDialogues);
    setMaxOrderIndex(newOrderIndex);
  };

  // Update the add dialogue logic
  const handleAddDialogue = () => {
    const newOrderIndex = maxOrderIndex + 1;
    console.log(
      `(we are in the front end in handleAddDialogue function right now) (debug 19) newOrderIndex: ${newOrderIndex}`,
    );
    setAlphaDialogues([
      ...alphaDialogues,
      {
        character: "",
        text: "",
        emotion: "default",
        prompt: null,
        order_index: newOrderIndex, // Assign new order_index
        volume: 1.0,
        speed: 1.0,
      },
    ]);
    setMaxOrderIndex(newOrderIndex);
  };

  // Update the delete dialogue logic
  const handleDeleteDialogue = async (dialogueIndex) => {
    try {
      const dialogue = alphaDialogues[dialogueIndex];
      const orderIndex = dialogue.order_index;

      if (orderIndex !== undefined) {
        // Delete audio files in backend
        await deleteDialogueAudio(userEmail, sessionTimestamp, orderIndex);
      }

      // Update UI
      const newDialogues = alphaDialogues.filter(
        (_, index) => index !== dialogueIndex,
      );
      setAlphaDialogues(newDialogues);

      // Update available audio set
      setAvailableDialogueAudios((prev) => {
        const newSet = new Set(prev);
        newSet.delete(dialogueIndex);
        return newSet;
      });

      // If this was the playing dialogue, stop it
      if (playingDialogueIndex === dialogueIndex) {
        if (dialogueAudioRef) {
          dialogueAudioRef.pause();
        }
        setPlayingDialogueIndex(null);
        setDialogueAudioRef(null);
      }

      // Save the updated screenplay
      await saveModifiedScreenplay(newDialogues, userEmail, sessionTimestamp);
    } catch (error) {
      console.error("Error deleting dialogue:", error);
      alert("Failed to delete dialogue. Please try again.");
    }
  };

  // Add these new states near other state declarations
  const [playingDialogueIndex, setPlayingDialogueIndex] = useState(null);
  const [dialogueAudioRef, setDialogueAudioRef] = useState(null);
  const [availableDialogueAudios, setAvailableDialogueAudios] = useState(
    new Set(),
  );

  // Update the handlePlayDialogue function to use order_index
  const handlePlayDialogue = async (dialogueIndex) => {
    const dialogue = alphaDialogues[dialogueIndex];
    const orderIndex = dialogue.order_index ?? dialogueIndex;

    if (playingDialogueIndex === dialogueIndex && dialogueAudioRef) {
      dialogueAudioRef.pause();
      setPlayingDialogueIndex(null);
      // Clean up the audio resources
      if (dialogueAudioRef.src) {
        URL.revokeObjectURL(dialogueAudioRef.src);
      }
      dialogueAudioRef.src = "";
      return;
    }

    // Apply single voice changes if the volume is changed volume
    if (
      alphaDialogues[dialogueIndex].volume !== undefined ||
      alphaDialogues[dialogueIndex].speed !== undefined
    ) {
      try {
        console.log("(handlePlayDialogue) (debug 0)", dialogueIndex);
        // First save the modified screenplay
        const saveSuccess = await saveModifiedScreenplay(
          alphaDialogues,
          userEmail,
          sessionTimestamp,
        );
        if (!saveSuccess) {
          throw new Error("Failed to save modified screenplay");
        }

        console.log("(handlePlayDialogue) (debug 1)");
        const response = await applySingleVoiceLoudnessAndSpeedChanges(
          userEmail,
          sessionTimestamp,
          dialogueIndex,
          alphaDialogues[dialogueIndex],
        );
        console.log("(handlePlayDialogue) (debug 2)");
        console.log(
          "(debug applySingleVoiceLoudnessAndSpeedChanges) response:",
          response,
        );
      } catch (error) {
        console.error("Error applying single voice changes:", error);
        alert("Failed to apply single voice changes. Please try again.");
      }
    }

    try {
      // Stop currently playing audio if any
      if (dialogueAudioRef) {
        dialogueAudioRef.pause();
        // Clean up the audio resources
        if (dialogueAudioRef.src) {
          URL.revokeObjectURL(dialogueAudioRef.src);
        }
        dialogueAudioRef.src = "";
      }

      const isShared = Boolean(viewingSharedProject);
      const playEmail = viewingSharedProject
        ? viewingSharedProject.email
        : userEmail;
      const playTimestamp = viewingSharedProject
        ? viewingSharedProject.timestamp
        : sessionTimestamp;

      // Add timestamp to URL to prevent caching
      const timestamp = new Date().getTime();
      // Use orderIndex instead of dialogueIndex for fetching audio
      const response = await fetch(
        `${API_ENDPOINTS.GET_VOICE_GENERATION}/${playEmail}/${playTimestamp}/${orderIndex}?is_shared=${isShared}&t=${timestamp}`,
      );

      if (!response.ok) {
        throw new Error("Failed to fetch dialogue audio");
      }

      const blob = await response.blob();
      const audio = new Audio(URL.createObjectURL(blob));

      audio.addEventListener("ended", () => {
        setPlayingDialogueIndex(null);
      });

      setDialogueAudioRef(audio);
      setPlayingDialogueIndex(dialogueIndex);
      audio.play();
    } catch (error) {
      console.error("Error playing dialogue:", error);
    }
  };

  // Add this useEffect to check audio availability
  useEffect(() => {
    if (alphaDialogues.length > 0 && (userEmail || viewingSharedProject)) {
      checkDialogueAudioAvailability();
    }
  }, [alphaDialogues, userEmail, sessionTimestamp, viewingSharedProject]);

  // Update the checkDialogueAudioAvailability function to use order_index
  const checkDialogueAudioAvailability = async () => {
    if (!userEmail || !sessionTimestamp) return;

    const newAvailableAudios = new Set();
    const isShared = Boolean(viewingSharedProject);
    const checkEmail = viewingSharedProject
      ? viewingSharedProject.email
      : userEmail;
    const checkTimestamp = viewingSharedProject
      ? viewingSharedProject.timestamp
      : sessionTimestamp;

    for (let i = 0; i < alphaDialogues.length; i++) {
      try {
        const dialogue = alphaDialogues[i];
        const orderIndex = dialogue.order_index ?? i;

        const response = await fetch(
          `${API_ENDPOINTS.GET_VOICE_GENERATION}/${checkEmail}/${checkTimestamp}/${orderIndex}?is_shared=${isShared}&t=${Date.now()}`,
        );
        if (response.ok) {
          newAvailableAudios.add(i);
        }
      } catch (error) {
        console.error("Error checking dialogue audio availability:", error);
      }
    }

    setAvailableDialogueAudios(newAvailableAudios);
  };

  // Add this state for tracking regeneration status
  const [regeneratingDialogue, setRegeneratingDialogue] = useState(null);

  // Add this function to handle dialogue regeneration
  const handleRegenerateDialogue = async (dialogueIndex) => {
    try {
      setRegeneratingDialogue(dialogueIndex);

      // Stop playing audio if this dialogue is currently playing
      if (playingDialogueIndex === dialogueIndex && dialogueAudioRef) {
        dialogueAudioRef.pause();
        setPlayingDialogueIndex(null);
        setDialogueAudioRef(null);
      }
      // Set volume to 1.0 for regenerated dialogue
      alphaDialogues[dialogueIndex].volume = 1.0;
      alphaDialogues[dialogueIndex].speed = 1.0;
      // First save the modified screenplay
      const saveSuccess = await saveModifiedScreenplay(
        alphaDialogues,
        userEmail,
        sessionTimestamp,
      );

      if (!saveSuccess) {
        throw new Error("Failed to save modified screenplay");
      }

      // Then regenerate the audio
      const response = await regenerateDialogueAudio(
        userEmail,
        sessionTimestamp,
        dialogueIndex,
        alphaDialogues[dialogueIndex],
      );

      if (!response.success) {
        throw new Error("Failed to regenerate dialogue audio");
      }

      // Update available audio set
      await checkDialogueAudioAvailability();

      // Set flag that voice changes need to be applied
      setNeedsVoiceApply(true);

      // Update original state for this dialogue
      const currentDialogue = alphaDialogues[dialogueIndex];
      const orderIndex = currentDialogue.order_index;

      // Check if this order_index exists in any existing state
      const existingStateIndex = Object.entries(
        originalDialogueStates,
      ).findIndex(([_, state]) => state.order_index === orderIndex);

      setOriginalDialogueStates((prev) => {
        const newState = {
          character: currentDialogue.character,
          text: currentDialogue.text,
          emotion: currentDialogue.emotion,
          voiceId:
            characterVoiceAssignments[currentDialogue.character]?.voice_id,
          order_index: orderIndex,
          volume: currentDialogue.volume ?? 1.0,
          speed: currentDialogue.speed ?? 1.0,
        };

        if (existingStateIndex >= 0) {
          return {
            ...prev,
            [existingStateIndex]: newState,
          };
        } else {
          const nextIndex = Object.keys(prev).length;
          return {
            ...prev,
            [nextIndex]: newState,
          };
        }
      });
    } catch (error) {
      console.error("Error regenerating dialogue:", error);
      alert("Failed to regenerate dialogue. Please try again.");
    } finally {
      setRegeneratingDialogue(null);
    }
  };

  // Add this new function to handle voice assignment confirmation
  const handleConfirmVoiceAssignments = async () => {
    try {
      // Convert voice assignments to the format expected by backend
      const voiceAssignments = {};
      Object.entries(characterVoiceAssignments).forEach(
        ([character, voice]) => {
          voiceAssignments[character] = voice.voice_id;
        },
      );

      // Save voice assignments
      const saveSuccess = await saveVoiceAssignments(
        voiceAssignments,
        userEmail,
        sessionTimestamp,
      );

      if (!saveSuccess) {
        throw new Error("Failed to save voice assignments");
      }

      // Navigate back to screenplay view
      setActiveView("screenplay");
    } catch (error) {
      console.error("Error saving voice assignments:", error);
      alert("Failed to save voice assignments. Please try again.");
    }
  };

  const [isApplyingVoice, setIsApplyingVoice] = useState(false);

  const handleApplyVoice = async () => {
    try {
      setIsApplyingVoice(true);

      // Save the modified screenplay for volume changes etc.
      const saveSuccess = await saveModifiedScreenplay(
        alphaDialogues,
        userEmail,
        sessionTimestamp,
      );
      if (!saveSuccess) {
        throw new Error("Failed to save modified screenplay");
      }

      // Apply voice changes
      const data = await applyVoiceChanges(userEmail, sessionTimestamp);

      // Check if BGM and SFX still exist after voice changes
      const hasBGM = await checkBGMExists(userEmail, sessionTimestamp);
      const hasSFX = await checkSFXExists(userEmail, sessionTimestamp);

      console.log("(debug 2894) hasBGM:", hasBGM, "hasSFX:", hasSFX);

      // TODO: Refactor this logic.
      setHasSFXGenerated(hasSFX);
      // Clear all SFX-related states when SFX is deleted
      if (!hasSFX) {
        // Clear sound effects data
        setSoundEffectsData([]);
        // Clear available SFX audios
        setAvailableSfxAudios(new Set());
        // Clear SFX volumes
        setSfxVolumes({});
        // Clear any playing SFX
        if (sfxAudioRef) {
          sfxAudioRef.pause();
          sfxAudioRef.src = "";
          setSfxAudioRef(null);
        }
        setPlayingSfxId(null);
        // Clear regenerating state
        setRegeneratingSfx(null);
      }

      // TODO: Refactor this logic.
      // Update states to reflect current status
      setHasBGMGenerated(hasBGM);
      // Clear all BGM-related states when BGM is deleted
      if (!hasBGM) {
        // Clear BGM description
        setMusicDescription("");
      }

      // Clean up existing multitrack
      if (alphaMultitrackRef) {
        alphaMultitrackRef.destroy();
        setAlphaMultitrackRef(null);
      }

      setAlphaMultitrackPaths(data);

      // Clear all cached tracks and audio elements
      setAlphaMultitrackTracks([]);

      // Initialize with new tracks
      await initializeMultitrack();
      await checkDialogueAudioAvailability();

      const newOriginalStates = {};
      alphaDialogues.forEach((dialogue, index) => {
        newOriginalStates[index] = {
          character: dialogue.character,
          text: dialogue.text,
          emotion: dialogue.emotion,
          voiceId: characterVoiceAssignments[dialogue.character]?.voice_id,
          order_index: dialogue.order_index ?? index,
          volume: dialogue.volume ?? 1.0,
          speed: dialogue.speed ?? 1.0,
        };
      });
      setOriginalDialogueStates(newOriginalStates);

      // Reset the needs apply flag
      setNeedsVoiceApply(false);

      // Navigate to multitrack view
      setActiveView("multitrack-audio");
    } catch (error) {
      console.error("Error applying voice changes:", error);
      alert("Failed to apply voice changes. Please try again.");
    } finally {
      setIsApplyingVoice(false);
    }
  };

  const isDialogueModified = (dialogue) => {
    // Find the original state with matching order_index
    console.log(
      `(we are in the front end in isDialogueModified function right now) (debug 21) dialogue: ${JSON.stringify(dialogue)}`,
    );
    console.log(
      `(we are in the front end in isDialogueModified function right now) (debug 21) originalDialogueStates: ${JSON.stringify(originalDialogueStates)}`,
    );
    const originalState = Object.values(originalDialogueStates).find(
      (state) => state.order_index === dialogue.order_index,
    );
    console.log(
      `(we are in the front end in isDialogueModified function right now) (debug 21) originalState: ${JSON.stringify(originalState)}`,
    );

    if (!originalState) return true; // If no original state exists, consider it modified

    return (
      dialogue.character !== originalState.character ||
      dialogue.text !== originalState.text ||
      dialogue.emotion !== originalState.emotion ||
      // Check if voice assignment changed
      characterVoiceAssignments[dialogue.character]?.voice_id !==
        originalState.voiceId
    );
  };

  // Add effect to stop playing audio if dialogue is modified
  useEffect(() => {
    if (
      playingDialogueIndex !== null &&
      isDialogueModified(alphaDialogues[playingDialogueIndex])
    ) {
      if (dialogueAudioRef) {
        dialogueAudioRef.pause();
      }
      setPlayingDialogueIndex(null);
      setDialogueAudioRef(null);
    }
  }, [alphaDialogues, characterVoiceAssignments]);

  // Add this helper function to check if any dialogue is modified
  const hasModifiedDialogues = () => {
    // Then check if any existing dialogues are modified
    return alphaDialogues.some((dialogue, index) =>
      isDialogueModified(dialogue),
    );
  };

  const hasDeletedDialogues = () => {
    // First check if lengths are different
    const originalLength = Object.keys(originalDialogueStates).length;
    if (alphaDialogues.length < originalLength) {
      return true;
    }
  };

  // Add new state to track if voice changes need to be applied
  const [needsVoiceApply, setNeedsVoiceApply] = useState(false);

  const handleSpeedChange = async (e, dialogueIndex) => {
    const newSpeed = parseFloat(e.target.value);
    const newDialogues = [...alphaDialogues];
    newDialogues[dialogueIndex] = {
      ...newDialogues[dialogueIndex],
      speed: newSpeed,
    };
    setAlphaDialogues(newDialogues);
    setNeedsVoiceApply(true);
  };

  // Add this helper function inside the component
  const getInitial = (email) => {
    return email ? email[0].toUpperCase() : "?";
  };

  // Add new state for original quota
  const [originalQuota, setOriginalQuota] = useState(10000);

  // Add this to your useEffect where you fetch the user's data
  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUserEmail) {
        const quota = await getUserOriginalQuota(currentUserEmail);
        setOriginalQuota(quota);
      }
    };
    fetchUserData();
  }, [currentUserEmail]);

  // Add these state variables after other state declarations
  const [bgmVolume, setBgmVolume] = useState(1.0);
  const [isPlayingBGM, setIsPlayingBGM] = useState(false);
  const [bgmAudioRef, setBgmAudioRef] = useState(null);
  const [isRegeneratingBGM, setIsRegeneratingBGM] = useState(false);

  // Add this function to handle BGM playback
  const handlePlayBGM = async () => {
    try {
      if (isPlayingBGM) {
        bgmAudioRef?.pause();
        setIsPlayingBGM(false);
        setBgmAudioRef(null);
        return;
      }

      // Always apply volume changes first
      await applyBGMVolumeChange(userEmail, sessionTimestamp, bgmVolume);

      // Then load and play the updated BGM
      const audio = new Audio();
      const blob = await getBGMAudio(userEmail, sessionTimestamp);
      audio.src = URL.createObjectURL(blob);
      setBgmAudioRef(audio);

      audio.onended = () => {
        setIsPlayingBGM(false);
        setBgmAudioRef(null);
      };

      await audio.play();
      setIsPlayingBGM(true);
    } catch (error) {
      console.error("Error playing BGM:", error);
      alert("Failed to play background music");
    }
  };

  // Add function to handle BGM volume change
  const handleBGMVolumeChange = async (newVolume) => {
    setBgmVolume(newVolume);
  };

  // Add function to handle BGM regeneration
  const handleRegenerateBGM = async () => {
    try {
      setIsRegeneratingBGM(true);

      // First save the modified description
      await saveModifiedBGMDescription(
        userEmail,
        sessionTimestamp,
        musicDescription,
      );

      // Then regenerate BGM
      await regenerateBackgroundMusic(userEmail, sessionTimestamp);

      // Reset volume to 1.0
      setBgmVolume(1.0);
      setHasBGMGenerated(true);
    } catch (error) {
      console.error("Error regenerating BGM:", error);
      alert("Failed to regenerate background music");
    } finally {
      setIsRegeneratingBGM(false);
    }
  };

  // Add function to handle BGM deletion
  const handleDeleteBGM = async () => {
    try {
      await deleteBGM(userEmail, sessionTimestamp);
      setMusicDescription("");
      setHasBGMGenerated(false);
      setBgmAudioRef(null);
      setIsPlayingBGM(false);
      setBgmVolume(1.0);

      // Clean up existing multitrack
      if (alphaMultitrackRef) {
        alphaMultitrackRef.destroy();
        setAlphaMultitrackRef(null);
      }

      // Clear all cached tracks and audio elements
      setAlphaMultitrackTracks([]);

      await initializeMultitrack();
      setActiveView("multitrack-audio");
    } catch (error) {
      console.error("Error deleting BGM:", error);
      alert("Failed to delete background music");
    }
  };

  // Add these state variables with the other state declarations
  const [isApplyingBGM, setIsApplyingBGM] = useState(false);

  // Add this handler function with the other handlers
  const handleApplyBGM = async () => {
    setIsApplyingBGM(true);
    try {
      // Apply the BGM to multitrack
      await applyModifiedBGM(userEmail, sessionTimestamp);

      // Clean up existing multitrack
      if (alphaMultitrackRef) {
        alphaMultitrackRef.destroy();
        setAlphaMultitrackRef(null);
      }
      // Clear all cached tracks and audio elements
      setAlphaMultitrackTracks([]);

      // Initialize with new tracks including updated SFX
      await initializeMultitrack();

      setActiveView("multitrack-audio");
    } catch (error) {
      console.error("Error applying BGM:", error);
      alert("Failed to apply background music");
    } finally {
      setIsApplyingBGM(false);
    }
  };

  const renderMusicDescriptionView = () => (
    <div className="bgm-text-input-view">
      <div className="bgm-text-input-header">
        <h3 className="bgm-text-input-title">
          🎵 Background Music Description
        </h3>
        <div className="header-buttons">
          <button
            className={`transform-button ${isGeneratingMusic ? "loading" : ""}`}
            onClick={handleGenerateBGM}
            disabled={
              isReadOnly || isGeneratingMusic || !musicDescription.trim()
            }
          >
            {isGeneratingMusic ? (
              <>
                <div className="loading-spinner" />
                <span className="button-text">Generating Music...</span>
              </>
            ) : (
              <>
                <span className="transform-icon">🎼</span>
                <span className="transform-text">Generate Music</span>
                <span className="transform-arrow">→</span>
              </>
            )}
          </button>
          <button
            className="apply-button"
            onClick={handleApplyBGM}
            disabled={!hasBGMGenerated || isApplyingBGM || isGeneratingMusic}
          >
            {isApplyingBGM ? (
              <>
                <div className="loading-spinner" />
                <span className="button-text">Applying Music...</span>
              </>
            ) : (
              <>
                <span className="transform-icon">🎨</span>
                <span className="transform-text">Apply Music</span>
                <span className="transform-arrow">→</span>
              </>
            )}
          </button>
        </div>
      </div>
      <div className="bgm-outer-container">
        <div className="bgm-controls-wrapper">
          <div className="bgm-action-controls">
            {/* Reorder volume control to come before play button */}
            <div className="bgm-volume-control">
              <div className="volume-slider-container">
                <div className="volume-wrapper">
                  <div className="volume-icon-wrapper">
                    {bgmVolume > 2.5 ? (
                      <FaVolumeUp className="volume-icon" />
                    ) : bgmVolume < 0.5 ? (
                      <FaVolumeOff className="volume-icon" />
                    ) : (
                      <FaVolumeDown className="volume-icon" />
                    )}
                  </div>
                  <input
                    type="range"
                    min="0.1"
                    max="3.0"
                    step="0.1"
                    value={bgmVolume}
                    onChange={(e) =>
                      handleBGMVolumeChange(parseFloat(e.target.value))
                    }
                    disabled={
                      !hasBGMGenerated || isGeneratingMusic || isRegeneratingBGM
                    }
                    className="volume-slider"
                  />
                </div>
                <div className="volume-value">{bgmVolume.toFixed(1)}x</div>
              </div>
            </div>
            <button
              className="bgm-control-button play-button" // Added play-button class
              onClick={handlePlayBGM}
              disabled={
                !hasBGMGenerated || isGeneratingMusic || isRegeneratingBGM
              }
            >
              {isPlayingBGM ? <FaPause size={12} /> : <FaPlay size={12} />}{" "}
              {/* Smaller icons */}
            </button>
            <button
              className="bgm-control-button"
              onClick={handleRegenerateBGM}
              disabled={isRegeneratingBGM || isGeneratingMusic}
            >
              {isRegeneratingBGM ? (
                <div className="loading-spinner" />
              ) : (
                "🔄" // Changed to emoji
              )}
            </button>
            <button
              className="bgm-control-button delete"
              onClick={handleDeleteBGM}
              disabled={!hasBGMGenerated || isGeneratingMusic || isApplyingBGM}
            >
              Delete {/* Change to text instead of icon */}
            </button>
          </div>
        </div>
        <div className="bgm-text-input-container">
          <textarea
            value={musicDescription}
            onChange={handleMusicDescriptionChange}
            placeholder="Describe the background music you want..."
            className="bgm-text-input-area"
            maxLength={1000}
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div className="bgm-word-count">
        Words: {musicDescription.length} / 1000
      </div>
    </div>
  );

  return (
    <div className="alpha-demo-page">
      <img
        className="product-title"
        onClick={handleBackLanding}
        src="/audiowizard.png"
        alt="AudioWizard"
      />
      <div className="alpha-demo-message">
        <div className="title-line">
          <span className="explore">Explore</span>
          <span className="our">Our</span>
          <span className="highlight">Product</span>
          <span className="separator">—</span>
        </div>
        <div className="subtitle-line">
          <span className="future">
            AI-Driven Audio Creation with Multicast TTS, Sound Effects, and
            Music
          </span>
        </div>
      </div>

      <div className="voice-exhibit-section">
        <div className="vertical-menu">
          <div className="menu-buttons">
            <button
              className={`menu-item ${activeView === "input" ? "active" : ""}`}
              onClick={() => setActiveView("input")}
              disabled={!isViewAvailable("input")}
            >
              <div className="menu-content">
                <span className="menu-step">1</span>
                <span className="menu-icon">📝</span>
                <span className="menu-text">Input Text</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "screenplay" ? "active" : ""}`}
              onClick={() => setActiveView("screenplay")}
              disabled={!isViewAvailable("screenplay")}
            >
              <div className="menu-content">
                <span className="menu-step">2</span>
                <span className="menu-icon">📜</span>
                <span className="menu-text">Screenplay</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "music-description" ? "active" : ""}`}
              onClick={() => setActiveView("music-description")}
              disabled={!isViewAvailable("music-description")}
            >
              <div className="menu-content">
                <span className="menu-step">3</span>
                <span className="menu-icon">🎵</span>
                <span className="menu-text">Background Music</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "sound-effects" ? "active" : ""}`}
              onClick={() => setActiveView("sound-effects")}
              disabled={!isViewAvailable("sound-effects")}
            >
              <div className="menu-content">
                <span className="menu-step">4</span>
                <span className="menu-icon">🔊</span>
                <span className="menu-text">Sound Effects</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "voice-assignment" ? "active" : ""}`}
              onClick={() => setActiveView("voice-assignment")}
              disabled={!isViewAvailable("voice-assignment")}
            >
              <div className="menu-content">
                <span className="menu-icon">🎯</span>
                <span className="menu-text">Voice Assignment</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "multitrack-audio" ? "active" : ""}`}
              onClick={() => setActiveView("multitrack-audio")}
              disabled={
                viewingSharedProject
                  ? false
                  : !isViewAvailable("multitrack-audio") ||
                    hasModifiedDialogues() ||
                    hasDeletedDialogues() ||
                    needsVoiceApply
              }
              title={
                viewingSharedProject
                  ? ""
                  : needsVoiceApply
                    ? "Please apply voice changes before accessing multitrack audio"
                    : hasModifiedDialogues()
                      ? "Please regenerate modified dialogues before accessing multitrack audio"
                      : ""
              }
            >
              <div className="menu-content">
                <span className="menu-icon">🎼</span>
                <span className="menu-text">Multitrack Audio</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "gallery" ? "active" : ""}`}
              onClick={() => setActiveView("gallery")}
            >
              <div className="menu-content">
                <span className="menu-icon">🎭</span>
                <span className="menu-text">Voice Gallery</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "projects" ? "active" : ""}`}
              onClick={() => setActiveView("projects")}
            >
              <div className="menu-content">
                <span className="menu-icon">📚</span>
                <span className="menu-text">My Projects</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "community" ? "active" : ""}`}
              onClick={() => setActiveView("community")}
            >
              <div className="menu-content">
                <span className="menu-icon">👥</span>
                <span className="menu-text">Community Showcase</span>
              </div>
            </button>

            {/* Add workspace section here */}
            <div
              className="workspace-section"
              onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
            >
              <div className="workspace-info">
                <div className="user-initial">
                  {getInitial(currentUserEmail)}
                </div>
                <div className="workspace-title">My Workspace</div>
              </div>
              {isUserMenuOpen && (
                <div className="user-menu">
                  <div className="credits-section">
                    <h3>Credits</h3>
                    <div className="credits-info">
                      <div className="credit-row">
                        <span>Total</span>
                        <span>{quota === "N/A" ? "N/A" : originalQuota}</span>
                      </div>
                      <div className="credit-row">
                        <span>Remaining</span>
                        <span>
                          {quota === "N/A" ? "N/A" : quota.toLocaleString()}
                        </span>
                      </div>
                    </div>
                    <button
                      className="upgrade-button"
                      onClick={() => navigate("/subscription")}
                    >
                      Upgrade
                    </button>
                  </div>
                  <div className="menu-items">
                    <button onClick={() => navigate("/subscription")}>
                      Subscription
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="voice-content">
          {activeView === "input" ? (
            <div className="text-input-view">
              <div className="text-input-header">
                <h3 className="text-input-title">📝 Input Your Text</h3>
                <button
                  className={`transform-button ${isTransforming ? "loading" : ""}`}
                  onClick={() => handleTransformTextToScreenplay(inputText)}
                  // Disable button if in read-only mode or if transforming
                  disabled={isReadOnly || isTransforming}
                >
                  {isTransforming ? (
                    <>
                      <div className="loading-spinner" />
                      <span className="button-text">Transforming...</span>
                    </>
                  ) : (
                    <>
                      <span className="transform-icon">✨</span>
                      <span className="transform-text">
                        Transform to Screenplay
                      </span>
                      <span className="transform-arrow">→</span>
                    </>
                  )}
                </button>
              </div>
              <div className="text-input-container">
                <textarea
                  value={inputText}
                  onChange={handleTextChange}
                  placeholder="Enter your text here (maximum 2000 words)..."
                  className="text-input-area"
                  maxLength={10000}
                  // Disable textarea in read-only mode
                  disabled={isReadOnly}
                />
                {textError && <div className="text-error">{textError}</div>}
                <div className="word-count">
                  Words:{" "}
                  {inputText.trim() ? inputText.trim().split(/\s+/).length : 0}{" "}
                  / {WORD_LIMIT}
                </div>
              </div>
            </div>
          ) : activeView === "screenplay" ? (
            <div className="alpha-screenplay-view">
              <div className="alpha-screenplay-header">
                <h3 className="alpha-screenplay-title">
                  📜 Generated Screenplay
                </h3>
                {!isReadOnly && (
                  <h4 className="alpha-screenplay-subtitle">
                    ✏️ Click to Modify
                  </h4>
                )}
                <div className="screenplay-buttons">
                  <button
                    className={`transform-button ${isAssigningVoices ? "loading" : ""}`}
                    onClick={handleVoiceAssignment}
                    disabled={isReadOnly}
                  >
                    {isAssigningVoices ? (
                      <>
                        <div className="loading-spinner" />
                        <span className="button-text">Assigning Voices...</span>
                      </>
                    ) : (
                      <>
                        <span className="transform-icon">🎯</span>
                        <span className="transform-text">Assign Voices</span>
                        <span className="transform-arrow">→</span>
                      </>
                    )}
                  </button>
                  <button
                    className={`transform-button ${isGeneratingMultitrack ? "loading" : ""}`}
                    onClick={handleGenerateVoice}
                    disabled={
                      isReadOnly ||
                      !Object.keys(characterVoiceAssignments).length > 0 ||
                      isGeneratingMultitrack
                    }
                  >
                    {isGeneratingMultitrack ? (
                      <>
                        <div className="loading-spinner" />
                        <span className="button-text">Generating Voice...</span>
                      </>
                    ) : (
                      <>
                        <span className="transform-icon">🔊</span>
                        <span className="transform-text">Generate Voice</span>
                        <span className="transform-arrow">→</span>
                      </>
                    )}
                  </button>
                  <button
                    className={`transform-button ${isApplyingVoice ? "loading" : ""}`}
                    onClick={handleApplyVoice}
                    disabled={
                      isReadOnly ||
                      !Object.keys(characterVoiceAssignments).length > 0 ||
                      isApplyingVoice ||
                      hasModifiedDialogues()
                    }
                  >
                    {isApplyingVoice ? (
                      <>
                        <div className="loading-spinner" />
                        <span className="button-text">Applying Changes...</span>
                      </>
                    ) : (
                      <>
                        <span className="transform-icon">🎤</span>
                        <span className="transform-text">Apply Voice</span>
                        <span className="transform-arrow">→</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
              <div className="alpha-screenplay">
                <div className="screenplay-grid">
                  {alphaDialogues.map((dialogue, dialogueIndex) => {
                    // Extract character and text from new format
                    const character = dialogue.character;
                    const text = dialogue.text;
                    console.log(
                      `(we are in the front end in screenplay view right now) (debug 18) dialogue: ${JSON.stringify(dialogue)}`,
                    );
                    const volume = dialogue.volume ?? 1.0;
                    const speed = dialogue.speed ?? 1.0;
                    return (
                      <React.Fragment key={dialogueIndex}>
                        {dialogueIndex > 0 && (
                          <div className="insert-dialogue-button-container">
                            <button
                              className="insert-dialogue-button"
                              onClick={() =>
                                handleInsertDialogue(dialogueIndex)
                              }
                              title="Insert dialogue"
                              disabled={isReadOnly}
                            >
                              +
                            </button>
                          </div>
                        )}
                        <div className="screenplay-row">
                          <div className="character-cell">
                            <input
                              type="text"
                              className="character-input"
                              value={
                                character
                                  ? character.endsWith(":")
                                    ? character.slice(0, -1)
                                    : character
                                  : ""
                              }
                              onChange={(e) => {
                                const newDialogues = [...alphaDialogues];
                                newDialogues[dialogueIndex] = {
                                  ...newDialogues[dialogueIndex],
                                  character: e.target.value,
                                };
                                setAlphaDialogues(newDialogues);
                              }}
                              disabled={isReadOnly}
                              placeholder="Character name"
                            />
                            {/* Add this for debugging */}
                          </div>
                          <div className="dialogue-cell">
                            <textarea
                              className="dialogue-textarea"
                              value={text || ""}
                              onChange={(e) => {
                                const newDialogues = [...alphaDialogues];
                                newDialogues[dialogueIndex] = {
                                  ...newDialogues[dialogueIndex],
                                  text: e.target.value,
                                };
                                setAlphaDialogues(newDialogues);
                              }}
                              disabled={isReadOnly}
                              placeholder="Enter dialogue..."
                              onInput={(e) => {
                                e.target.style.height = "auto";
                                e.target.style.height =
                                  e.target.scrollHeight + "px";
                              }}
                              ref={(textarea) => {
                                if (textarea) {
                                  textarea.style.height = "auto";
                                  textarea.style.height =
                                    textarea.scrollHeight + "px";
                                }
                              }}
                            />
                            {/* First the emotion dropdown */}
                            {dialogue.hasOwnProperty("emotion") && (
                              <div className="emotion-cell">
                                <div className="custom-select">
                                  <button
                                    className="emotion-select"
                                    onClick={(e) => {
                                      e.currentTarget
                                        .closest(".custom-select")
                                        .classList.toggle("open");
                                    }}
                                    disabled={isReadOnly}
                                  >
                                    {dialogue.emotion.charAt(0).toUpperCase() +
                                      dialogue.emotion.slice(1)}
                                  </button>
                                  <div className="emotion-dropdown">
                                    {[
                                      "default",
                                      "happy",
                                      "angry",
                                      "anxious",
                                      "surprised",
                                      "disgust",
                                      "sad",
                                    ].map((emotion) => (
                                      <div
                                        key={emotion}
                                        className={`emotion-option ${dialogue.emotion === emotion ? "selected" : ""}`}
                                        onClick={() => {
                                          const newDialogues = [
                                            ...alphaDialogues,
                                          ];
                                          newDialogues[dialogueIndex] = {
                                            ...newDialogues[dialogueIndex],
                                            emotion: emotion,
                                          };
                                          setAlphaDialogues(newDialogues);
                                          // Close dropdown after selection
                                          document
                                            .querySelectorAll(
                                              ".custom-select.open",
                                            )
                                            .forEach((el) =>
                                              el.classList.remove("open"),
                                            );
                                        }}
                                      >
                                        {emotion.charAt(0).toUpperCase() +
                                          emotion.slice(1)}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* Add volume slider if dialogue has volume property */}
                            {
                              <div className="volume-cell">
                                <div className="volume-slider-container">
                                  <div className="volume-wrapper">
                                    <div className="volume-icon-wrapper">
                                      {volume > 2.5 ? (
                                        <FaVolumeUp className="volume-icon" />
                                      ) : volume < 0.5 ? (
                                        <FaVolumeOff className="volume-icon" />
                                      ) : (
                                        <FaVolumeDown className="volume-icon" />
                                      )}
                                    </div>
                                    <input
                                      type="range"
                                      min="0.1"
                                      max="3.0"
                                      step="0.1"
                                      value={
                                        !availableDialogueAudios.has(
                                          dialogueIndex,
                                        ) ||
                                        regeneratingDialogue ===
                                          dialogueIndex ||
                                        isDialogueModified(dialogue)
                                          ? 1.0
                                          : Number(volume)
                                      }
                                      onChange={(e) =>
                                        handleVolumeChange(e, dialogueIndex)
                                      }
                                      className="volume-slider"
                                      disabled={
                                        !availableDialogueAudios.has(
                                          dialogueIndex,
                                        ) ||
                                        regeneratingDialogue ===
                                          dialogueIndex ||
                                        isDialogueModified(dialogue)
                                      }
                                    />
                                  </div>
                                  <div className="volume-value">
                                    {(!availableDialogueAudios.has(
                                      dialogueIndex,
                                    ) ||
                                    regeneratingDialogue === dialogueIndex ||
                                    isDialogueModified(dialogue)
                                      ? 1.0
                                      : Number(volume)
                                    ).toFixed(1)}
                                    x
                                  </div>
                                </div>
                              </div>
                            }
                            {/* Add new speed control cell */}
                            <div className="speed-cell">
                              <div className="speed-slider-container">
                                <div className="speed-wrapper">
                                  <div className="speed-icon-wrapper">
                                    {speed >= 1.0 ? (
                                      <FaForward className="speed-icon" />
                                    ) : (
                                      <FaBackward className="speed-icon" />
                                    )}
                                  </div>
                                  <input
                                    type="range"
                                    min="0.25"
                                    max="2.0"
                                    step="0.05"
                                    value={
                                      !availableDialogueAudios.has(
                                        dialogueIndex,
                                      ) ||
                                      regeneratingDialogue === dialogueIndex ||
                                      isDialogueModified(dialogue)
                                        ? 1.0
                                        : Number(speed ?? 1.0)
                                    }
                                    onChange={(e) =>
                                      handleSpeedChange(e, dialogueIndex)
                                    }
                                    className="speed-slider"
                                    disabled={
                                      !availableDialogueAudios.has(
                                        dialogueIndex,
                                      ) ||
                                      regeneratingDialogue === dialogueIndex ||
                                      isDialogueModified(dialogue)
                                    }
                                  />
                                </div>
                                <div className="speed-value">
                                  {(!availableDialogueAudios.has(
                                    dialogueIndex,
                                  ) ||
                                  regeneratingDialogue === dialogueIndex ||
                                  isDialogueModified(dialogue)
                                    ? 1.0
                                    : Number(speed ?? 1.0)
                                  ).toFixed(2)}
                                  x
                                </div>
                              </div>
                            </div>
                            {/* Then the play button */}
                            <button
                              className={`dialogue-play-button ${playingDialogueIndex === dialogueIndex ? "active" : ""}`}
                              onClick={() => handlePlayDialogue(dialogueIndex)}
                              disabled={
                                !availableDialogueAudios.has(dialogueIndex) ||
                                regeneratingDialogue === dialogueIndex ||
                                isDialogueModified(dialogue)
                              }
                              title={
                                regeneratingDialogue === dialogueIndex
                                  ? "Regenerating audio..."
                                  : isDialogueModified(dialogue)
                                    ? "Dialogue modified - regenerate audio to play"
                                    : availableDialogueAudios.has(dialogueIndex)
                                      ? "Play/Pause dialogue"
                                      : "Audio not available"
                              }
                            >
                              {playingDialogueIndex === dialogueIndex ? (
                                <IoPauseCircle size={24} />
                              ) : (
                                <IoPlayCircle size={24} />
                              )}
                            </button>
                            {/* Add regenerate button */}
                            <button
                              className={`dialogue-regenerate-button ${regeneratingDialogue === dialogueIndex ? "loading" : ""}`}
                              onClick={() =>
                                handleRegenerateDialogue(dialogueIndex)
                              }
                              disabled={
                                isReadOnly ||
                                regeneratingDialogue === dialogueIndex ||
                                !characterVoiceAssignments[character] // Disable if no voice assigned
                              }
                              title="Regenerate audio"
                            >
                              {regeneratingDialogue === dialogueIndex ? (
                                <div className="loading-spinner" />
                              ) : (
                                "🔄"
                              )}
                            </button>
                            {/* Finally the delete button */}
                            <div className="delete-cell">
                              {!isReadOnly && (
                                <button
                                  className="dialogue-delete"
                                  onClick={() =>
                                    handleDeleteDialogue(dialogueIndex)
                                  }
                                  title="Delete dialogue"
                                >
                                  ✕
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                  {/* Add insert button after the last dialogue */}
                  {!isReadOnly && alphaDialogues.length > 0 && (
                    <div className="insert-dialogue-button-container">
                      <button
                        className="insert-dialogue-button"
                        onClick={handleAddDialogue}
                        title="Add dialogue"
                      >
                        +
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : activeView === "voice-assignment" ? (
            <div className="alpha-voice-assignment-view">
              <div className="alpha-voice-assignment-header">
                <h3 className="alpha-voice-assignment-title">
                  🎯 Character Voice Assignment
                </h3>
                <button
                  className="confirm-voice-assignments-button"
                  onClick={handleConfirmVoiceAssignments}
                >
                  <span className="button-icon">✓</span>
                  <span className="button-text">Confirm</span>
                </button>
              </div>
              <div className="alpha-voice-assignment-content">
                {Object.entries(characterVoiceAssignments).map(
                  ([character, voice]) => (
                    <div key={character} className="alpha-character-voice-card">
                      <div className="alpha-character-info">
                        <div className="alpha-character-avatar">
                          {character[0].toUpperCase()}
                        </div>
                        <h4 className="alpha-character-name">{character}</h4>
                      </div>

                      <div className="alpha-assigned-voice">
                        <div
                          className="alpha-voice-avatar"
                          style={{
                            backgroundColor: getColorFromSeed(voice.name),
                          }}
                        >
                          {voice.name[0]}
                        </div>
                        <div className="alpha-voice-details">
                          <h4>{voice.name}</h4>
                          <p>{voice.description}</p>
                          <div className="alpha-voice-tags">
                            {voice.labels &&
                              Object.entries(voice.labels).map(
                                ([key, value], i) => (
                                  <span key={i} className="alpha-voice-tag">
                                    {`${key}: ${value}`}
                                  </span>
                                ),
                              )}
                          </div>
                        </div>
                        <div className="alpha-voice-actions">
                          <button
                            className="alpha-preview-voice"
                            onClick={() => handlePlayVoice(voice)}
                          >
                            {isPlaying &&
                            selectedVoice?.filename === voice.filename ? (
                              <IoPauseCircle size={24} />
                            ) : (
                              <IoPlayCircle size={24} />
                            )}
                          </button>
                          <button
                            className="alpha-change-voice"
                            onClick={() =>
                              setActiveCharacterForVoice(character)
                            }
                          >
                            Change Voice
                          </button>
                        </div>
                      </div>
                    </div>
                  ),
                )}
              </div>
            </div>
          ) : activeView === "multitrack-audio" ? (
            <div className="alpha-multitrack-view">
              <PanelGroup direction="horizontal">
                <Panel minSize={20} defaultSize={25}>
                  <div className="alpha-multitrack-screenplay-panel">
                    <div className="alpha-screenplay-header">
                      <h3 className="alpha-screenplay-title">📖 Screenplay</h3>
                      <div className="button-group">
                        <button
                          className={`generate-bgm-button ${isGeneratingBGM ? "loading" : ""}`}
                          onClick={handleGenerateBGMDescription}
                          // Disable button if in read-only mode or if generating
                          disabled={isReadOnly || isGeneratingBGM}
                        >
                          {isGeneratingBGM ? (
                            <>
                              <div className="loading-spinner" />
                              <span className="button-text">Generating...</span>
                            </>
                          ) : (
                            <>
                              <span className="button-icon">🎵</span>
                              <span className="button-text">BGM</span>
                              <span className="button-arrow">→</span>
                            </>
                          )}
                        </button>
                        <button
                          className={`generate-sfx-button ${isGeneratingSFX ? "loading" : ""}`}
                          onClick={handleGenerateSFXDescription}
                          // Disable button if in read-only mode or if generating
                          disabled={isReadOnly || isGeneratingSFX}
                        >
                          {isGeneratingSFX ? (
                            <>
                              <div className="loading-spinner" />
                              <span className="button-text">Generating...</span>
                            </>
                          ) : (
                            <>
                              <span className="button-icon">🔊</span>
                              <span className="button-text">Sound Effects</span>
                              <span className="button-arrow">→</span>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                    {renderAlphaMultitrackScreenplay()}
                  </div>
                </Panel>
                <PanelResizeHandle />
                <Panel minSize={20} defaultSize={25}>
                  <div className="alpha-multitrack-container">
                    <div className="alpha-multitrack-title-section">
                      <h3 className="alpha-multitrack-title">
                        🎼 Multitrack Audio
                      </h3>
                      <div className="alpha-multitrack-controls">
                        <button
                          className={`alpha-play-pause-button ${isPlayingAlphaMultitrack ? "playing" : ""}`}
                          onClick={handlePlayPauseAlphaMultitrack}
                        >
                          {isPlayingAlphaMultitrack ? <FaPause /> : <FaPlay />}
                        </button>

                        <div
                          className={`alpha-download-dropdown ${isDropdownOpen ? "active" : ""}`}
                        >
                          <button
                            className={`alpha-download-button ${isReadOnly ? "disabled" : ""}`}
                            onClick={() =>
                              !isReadOnly && setIsDropdownOpen(!isDropdownOpen)
                            }
                            disabled={isReadOnly}
                          >
                            <span className="button-icon">⬇️</span>
                            <span className="button-text">Download</span>
                            <span className="dropdown-arrow">▼</span>
                          </button>
                          {!isReadOnly && isDropdownOpen && (
                            <div className="dropdown-content">
                              <button
                                onClick={() => {
                                  handleCombinedDownload();
                                  setIsDropdownOpen(false);
                                }}
                              >
                                Combined Track
                              </button>
                              <button
                                onClick={() => {
                                  handleSeparateDownload();
                                  setIsDropdownOpen(false);
                                }}
                              >
                                Separate Tracks
                              </button>
                            </div>
                          )}
                        </div>
                        <button
                          className={`alpha-share-button ${isSharing ? "loading" : ""}`}
                          onClick={handleShareProject}
                          disabled={isReadOnly || isSharing}
                        >
                          {isSharing ? (
                            <>
                              <div className="loading-spinner" />
                              <span className="button-text">Sharing...</span>
                            </>
                          ) : (
                            <>
                              <span className="button-icon">
                                <FaShare />
                              </span>
                              <span className="button-text">Share</span>
                            </>
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="alpha-multitrack-wrapper">
                      <div className="alpha-track-labels">
                        {alphaMultitrackTracks.map((track) => (
                          <div key={track.id} className="alpha-audio-label">
                            {track.id}
                          </div>
                        ))}
                      </div>
                      <div
                        ref={alphaMultitrackContainerRef}
                        style={{ width: "100%", height: "200px" }}
                      ></div>
                    </div>
                  </div>
                </Panel>
              </PanelGroup>
            </div>
          ) : activeView === "music-description" ? (
            renderMusicDescriptionView()
          ) : activeView === "sound-effects" ? (
            renderSoundEffectsView()
          ) : activeView === "projects" ? (
            <div className="projects-view">
              <h3 className="projects-title">📚 My Projects</h3>
              {isLoadingProjects ? (
                <div className="projects-loading">Loading projects...</div>
              ) : (
                <div className="projects-grid">
                  {projects.map((project) => (
                    <div
                      key={project.timestamp}
                      className="project-card"
                      onClick={() => handleProjectSelect(project)}
                    >
                      <h4 className="project-title">{project.title}</h4>
                      <div className="project-date">
                        {new Date(
                          project.last_modified * 1000,
                        ).toLocaleDateString()}
                      </div>
                    </div>
                  ))}
                  {projects.length === 0 && (
                    <div className="no-projects">
                      No projects yet. Start by creating a new audiobook!
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : activeView === "community" ? (
            <div className="community-showcase">
              <h3 className="community-title">👥 Community Showcase</h3>
              {isLoadingCommunity ? (
                <div className="community-loading">
                  Loading shared projects...
                </div>
              ) : (
                <div className="community-grid">
                  {communityProjects.map((project) => (
                    <div
                      key={`${project.email}-${project.timestamp}`}
                      className="community-card"
                      onClick={() => handleCommunityProjectSelect(project)}
                    >
                      <h4 className="project-title">{project.title}</h4>
                      <div className="project-info">
                        <div className="project-details">
                          <span className="project-author">
                            {project.email}
                          </span>
                          <span className="project-date">
                            {new Date(project.timestamp).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              },
                            )}
                          </span>
                        </div>
                        <button
                          className={`reaction-button ${project.hasReacted ? "active" : ""}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleReaction(project.email, project.timestamp);
                          }}
                          disabled={
                            loadingReactions[
                              `${project.email}-${project.timestamp}`
                            ]
                          }
                        >
                          <FaThumbsUp />
                          <span className="reaction-count">
                            {project.reactions}
                          </span>
                        </button>
                      </div>
                    </div>
                  ))}
                  {communityProjects.length === 0 && (
                    <div className="no-projects">
                      No shared projects yet. Be the first to share your
                      creation!
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <>
              <h3 className="voice-exhibit-title">🎭 Voice Gallery</h3>
              <div className="voice-search">
                <input
                  type="text"
                  placeholder="Search voices..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="voice-search-input"
                />
              </div>
              {!voicePreviewsLoaded ? (
                <div className="voice-loading">Loading voice previews...</div>
              ) : (
                <div className="voice-list">
                  {filteredVoices.map((voice, index) => (
                    <div key={index} className="voice-item">
                      <div
                        className="voice-avatar"
                        style={{
                          backgroundColor: getColorFromSeed(voice.name),
                        }}
                      >
                        {voice.name.charAt(0)}
                      </div>
                      <div className="voice-info">
                        <h4 className="voice-name">{voice.name}</h4>
                        <p className="voice-description">
                          {voice.description || "No description available"}
                        </p>
                        <div className="voice-tags">
                          {voice.labels &&
                            Object.entries(voice.labels).map(
                              ([key, value], i) => (
                                <span
                                  key={i}
                                  className="voice-tag"
                                >{`${key}: ${value}`}</span>
                              ),
                            )}
                        </div>
                      </div>
                      <button
                        className="play-voice-button"
                        onClick={() => handlePlayVoice(voice)}
                        title={
                          isPlaying &&
                          selectedVoice?.filename === voice.filename
                            ? "Pause"
                            : "Play"
                        }
                      >
                        {isPlaying &&
                        selectedVoice?.filename === voice.filename ? (
                          <IoPauseCircle size={24} />
                        ) : (
                          <IoPlayCircle size={24} />
                        )}
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Voice Selection Modal */}
      {activeCharacterForVoice && (
        <div className="alpha-voice-selection-modal">
          <div className="alpha-modal-content">
            <div className="alpha-modal-header">
              <h3>Select Voice for {activeCharacterForVoice}</h3>
              <button
                className="alpha-modal-close"
                onClick={() => {
                  setActiveCharacterForVoice(null);
                  setModalSearchQuery("");
                }}
              >
                ×
              </button>
            </div>

            <div className="alpha-modal-search">
              <input
                type="text"
                placeholder="Search voices by name or characteristics..."
                value={modalSearchQuery}
                onChange={(e) => {
                  console.log("Search query changed:", e.target.value);
                  setModalSearchQuery(e.target.value);
                }}
                className="alpha-modal-search-input"
              />
            </div>

            <div className="alpha-modal-voices">
              {filteredModalVoices.map((voice) => (
                <div
                  key={voice.voice_id}
                  className={`alpha-modal-voice-item ${
                    characterVoiceAssignments[activeCharacterForVoice]?.name ===
                    voice.name
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => {
                    setCharacterVoiceAssignments((prev) => ({
                      ...prev,
                      [activeCharacterForVoice]: voice,
                    }));
                    setActiveCharacterForVoice(null);
                    setModalSearchQuery("");
                  }}
                >
                  <div
                    className="alpha-modal-voice-avatar"
                    style={{ backgroundColor: getColorFromSeed(voice.name) }}
                  >
                    {voice.name[0]}
                  </div>
                  <div className="alpha-modal-voice-info">
                    <h4 className="alpha-modal-voice-name">{voice.name}</h4>
                    <p className="alpha-modal-voice-description">
                      {voice.description}
                    </p>
                    <div className="alpha-modal-voice-tags">
                      {voice.labels &&
                        Object.entries(voice.labels).map(([key, value], i) => (
                          <span key={i} className="alpha-modal-voice-tag">
                            {`${key}: ${value}`}
                          </span>
                        ))}
                    </div>
                  </div>
                  <button
                    className="alpha-modal-preview"
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePlayVoice(voice);
                    }}
                  >
                    {isPlaying && selectedVoice?.filename === voice.filename ? (
                      <IoPauseCircle size={20} />
                    ) : (
                      <IoPlayCircle size={20} />
                    )}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Place audio element here so it's accessible to all sections */}
      <audio
        ref={audioRef}
        onEnded={handleAudioEnded}
        style={{ display: "none" }}
      />
    </div>
  );
}
export default LandingPage;
