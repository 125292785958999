const API_BASE_URL = "https://api.audiowizard.ai";
// const API_BASE_URL = "http://localhost:8001";

export const API_ENDPOINTS = {
  SUBSCRIBE: `${API_BASE_URL}/subscribe/`,
  TRANSFORM: `${API_BASE_URL}/screenplay`,
  ASSIGN_VOICES: `${API_BASE_URL}/assign-voices`,
  GENERATE_VOICES: `${API_BASE_URL}/generate-voices`,
  GET_VOICE_GENERATION: `${API_BASE_URL}/get-voice-generation`,
  GET_WORD_ALIGNMENT: `${API_BASE_URL}/get-word-alignment`,
  GET_MULTITRACK: `${API_BASE_URL}/get-multitrack`,
  GET_COMBINED_TRACK: `${API_BASE_URL}/get-combined-track`,
  GET_SEPARATE_TRACKS_ZIP: `${API_BASE_URL}/get-separate-tracks-zip`,
  GET_REMAINING_QUOTA: `${API_BASE_URL}/get_quota/`,
  CALCULATE_QUOTA_USAGE: `${API_BASE_URL}/calculate-quota-usage`,
  SUBTRACT_QUOTA: `${API_BASE_URL}/subtract_quota/`,
  GENERATE_BGM_DESCRIPTION: `${API_BASE_URL}/generate-bgm-description`,
  GENERATE_BGM: `${API_BASE_URL}/generate-bgm`,
  GET_BGM_TRACK: `${API_BASE_URL}/get-bgm-track`,
  GET_BGM_AUDIO: `${API_BASE_URL}/get-bgm-audio`,
  GENERATE_SFX_DESCRIPTION: `${API_BASE_URL}/generate-sfx-description`,
  GET_SFX_DESCRIPTION: `${API_BASE_URL}/get-sfx-description`,
  SAVE_MODIFIED_SFX: `${API_BASE_URL}/save-modified-sfx`,
  GENERATE_SFX: `${API_BASE_URL}/generate-sfx`,
  GET_SFX_TRACK: `${API_BASE_URL}/get-sfx-track`,
  SAVE_MODIFIED_SCREENPLAY: `${API_BASE_URL}/save_modified_screenplay`,
  GET_USER_PROJECTS: `${API_BASE_URL}/get-user-projects`,
  GET_PROJECT_DATA: `${API_BASE_URL}/get-project-data`,
  SAVE_MODIFIED_CHARACTER_DIALOGUES: `${API_BASE_URL}/save_modified_character_dialogues`,
  SHARE_PROJECT: `${API_BASE_URL}/share-project`,
  GET_COMMUNITY_PROJECTS: `${API_BASE_URL}/get-community-projects`,
  GET_COMMUNITY_PROJECT: `${API_BASE_URL}/get-community-project`,
  ADD_REACTION: `${API_BASE_URL}/add-reaction`,
  GET_SFX_AUDIO: `${API_BASE_URL}/get-sfx-audio`,
  REGENERATE_SINGLE_SFX: `${API_BASE_URL}/regenerate-single-sfx`,
  UPDATE_SFX_DESCRIPTION: `${API_BASE_URL}/update-sfx-description`,
  APPLY_SFX: `${API_BASE_URL}/apply-sfx`,
  DELETE_SFX: `${API_BASE_URL}/delete-sfx`,
  SAVE_VOICE_ASSIGNMENTS: `${API_BASE_URL}/save-voice-assignments`,
  DELETE_DIALOGUE_AUDIO: `${API_BASE_URL}/delete-dialogue-audio`,
  REGENERATE_DIALOGUE_AUDIO: `${API_BASE_URL}/regenerate-dialogue-audio`,
  APPLY_VOICE_CHANGES: `${API_BASE_URL}/apply-voice-changes`,
  APPLY_SINGLE_VOICE_LOUDNESS_AND_SPEED_CHANGES: `${API_BASE_URL}/apply-single-voice-loudness-and-speed-changes`,
  APPLY_SINGLE_SFX_VOLUME_CHANGES: `${API_BASE_URL}/apply-single-sfx-volume-changes`,
  SEND_VERIFICATION: `${API_BASE_URL}/send-verification`,
  VERIFY_EMAIL: `${API_BASE_URL}/verify-email`,
  GET_USER_ORIGINAL_QUOTA: `${API_BASE_URL}/api/user/original-quota`,
  GET_PLANS: `${API_BASE_URL}/api/stripe/plans`,
  GET_STRIPE_HOBBYIST_URL: `${API_BASE_URL}/api/stripe/hobbyist-url`,
  GET_STRIPE_CREATOR_URL: `${API_BASE_URL}/api/stripe/creator-url`,
  GET_STRIPE_LARGER_CREATOR_URL: `${API_BASE_URL}/api/stripe/larger-creator-url`,
  GET_STRIPE_PORTAL_URL: `${API_BASE_URL}/api/stripe/portal-url`,
  GET_USER_CURRENT_PLAN: `${API_BASE_URL}/api/user/current-plan`,
  APPLY_BGM_VOLUME: `${API_BASE_URL}/apply-bgm-volume`,
  DELETE_BGM: `${API_BASE_URL}/delete-bgm`,
  REGENERATE_BGM: `${API_BASE_URL}/regenerate-bgm`,
  SAVE_MODIFIED_BGM_DESCRIPTION: `${API_BASE_URL}/save-modified-bgm-description`,
  APPLY_BGM: `${API_BASE_URL}/apply-bgm`,
};
